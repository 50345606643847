
    import {Component, Mixins, Watch} from 'vue-property-decorator'
    import TableControls from '@/components/Table/TableControls.vue'
    import TableFeedItemsGroup from '@/components/Table/Feed/TableFeedItemsGroup.vue'
    import TableContentView from '@/components/Table/Table/TableContentView.vue'
    import SignUpContainer from '@/components/SignUp/SignUpContainer.vue'
    import {DataViewType} from '@/typings/typings'
    import {IApplication} from '@/typings/IApplication'
    import AdaptiveMixin from '@/components/Base/AdaptiveMixin'
    import TableContentViewMobile from '@/components/Table/Table/TableContentViewMobile.vue'
    import {store} from '@/store/store'
    import Requester from '@/utils/Requester'
    import {needLoadByScroll} from '@/utils/utils'
    import {IRequestMeta} from '@/typings/IRequestMeta'

    @Component({
        components: {TableContentViewMobile, TableContentView, TableFeedItemsGroup, TableControls, SignUpContainer},
    })
    export default class Favorites2 extends Mixins<AdaptiveMixin>(AdaptiveMixin) {
        items: Array<IApplication> = []

        fullReload = true
        nextLoad = false

        dataViewTypes = DataViewType

        currentPage = 1
        maxPage = -1

        subscriptionError = false

        get currentDataViewType() {
            return store.getters['local_storage/viewState']
        }

        get userId() {
            return store.getters['local_storage/loggedUser'].user_id
        }

        get favoritesIds(): Array<number> {
            return store.getters['favorites/favoritesIds']
        }

        @Watch('favoritesIds')
        onFavoritesIdsUpdate() {
            this.items = this.items.filter(item => this.favoritesIds.includes(item.id))
        }

        async handleScroll() {
            if (needLoadByScroll()) {
                await this.loadNext()
            }
        }

        async loadNext() {
            if (this.nextLoad || this.fullReload) {
                return
            }

            if (this.currentPage >= this.maxPage) {
                return
            }

            if (this.subscriptionError) {
                return
            }

            this.nextLoad = true
            this.currentPage++
            this.items = [
                ...this.items,
                ...(await this.loadItems())?.data ?? [],
            ]

            this.nextLoad = false
        }

        async reload() {
            this.currentPage = 1
            this.fullReload = true
            this.items = (await this.loadItems())?.data ?? []
            this.fullReload = false
        }

        async loadItems() {
            try {
                const response = await Requester.get<{ data: Array<IApplication>, meta: IRequestMeta }>('favorites', {
                    page: this.currentPage,
                    per_page: 20,
                    user_id: this.userId
                })

                this.maxPage = response.meta.total_pages

                return response
            } catch (e) {
                this.subscriptionError = true
            }
        }

        async mounted() {
            window.addEventListener('scroll', this.handleScroll)
            await this.reload()
        }

        beforeDestroy() {
            window.removeEventListener('scroll', this.handleScroll)
        }
    }
