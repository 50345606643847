
    import {Component, Mixins} from 'vue-property-decorator'
    import FitSize from '@/utils/FitSize.vue'
    import InlineSvg from 'vue-inline-svg'
    import IconPathsMixin from '@/components/Base/IconPathsMixin'
    import {ItemsContent} from '@/components/Base/ItemsContent'
    import TableFavoriteIcon from '@/components/Table/Table/TableFavoriteIcon.vue'
    import FormatHelper from '@/utils/FormatHelper'
    import SubscriptionErrorText from '@/components/Table/SubscriptionErrorText.vue'
    import {store} from '@/store/store'
    import {IMetricsByApplicationKeyword} from '@/typings/IMetricsByApplicationKeyword'
    import DetailsTopAppsByKeyword from '@/views/Details/DetailsTopAppsByKeyword.vue'
    import {IApplicationCachedRawData} from '@/typings/IApplicationAdditionalData'
    import {ITopChart} from '@/typings/ITopChart'
    import {makeUrl} from '@/views/Details/utils'
    import {formatReleaseDate, getCategoryName, ITopChartsItemsGroup} from '@/components/TopChartsTable/utils'
    import {val} from 'cheerio/lib/api/attributes'

    @Component({
        components: {
            FitSize,
            InlineSvg,
            TableFavoriteIcon,
            SubscriptionErrorText,
            DetailsTopAppsByKeyword,
        },
    })
    export default class TopChartsTable extends Mixins<IconPathsMixin, FormatHelper, ItemsContent<ITopChart>>(FormatHelper, IconPathsMixin, ItemsContent) {

        showDialog = false
        currentKeyword: string | null = null
        currentAppsByKeyword: Array<IApplicationCachedRawData> = []

        getRevenue(item: ITopChart) {
            return item.revenues ? String(item.revenues) + '$' : '-'
        }

        getDownloads(item: ITopChart) {
            return String(item.downloads ?? '-')
        }

        get groupedItems() {
            const result: Record<string, ITopChartsItemsGroup> = {}

            for (const item of this.items) {
                const key = `${item.type}_${item.category}`
                if (!result[key]) {
                    result[key] = {
                        type: item.type,
                        category: item.category,
                        items: [item]
                    }
                } else {
                    result[key].items.push(item)
                }
            }

            return Object.values(result)
        }

        get isLogged() {
            return store.getters['local_storage/isLogged']
        }

        formatReleaseDate(value: ITopChart) {
            return formatReleaseDate(value)
        }

        formatCategoryName(value: string) {
            return getCategoryName(value)
        }

        formatType(type: 'free' | 'grossing') {
            switch (type) {
                case 'free':
                    return 'Installs'
                case 'grossing':
                    return 'Grossing'
            }
        }

        getData(value: ITopChart) {
            return value.application_cached_raw_datum.raw_data
        }

        getAppName(value: ITopChart) {
            return this.getData(value).name
        }

        getAppIcon(value: ITopChart) {
            switch (value.os) {
                case 'android':
                    return this.icons.androidIcon
                case 'ios':
                    return this.icons.iosIcon
            }

            throw new Error('Cant recognize icon!')
        }

        getApplicationIconRuleUrl(value: ITopChart) {
            return value.application_cached_raw_datum.raw_data.icon
        }

        redirectToUrl(value: ITopChart) {
            window.open(makeUrl(value.store_app_id, value.os))
        }

        getFillColor() {
            return '#D1D1D1'
        }
    }
