
    import {Component, Mixins, Prop, Watch} from 'vue-property-decorator'
    import {IFilterItem} from '@/components/Table/data'
    import {store} from '@/store/store'
    import AdaptiveMixin from '@/components/Base/AdaptiveMixin'

    @Component
    export default class FilterItemMultiple extends Mixins<AdaptiveMixin>(AdaptiveMixin) {
        @Prop({required: true}) title!: string
        @Prop({required: true}) type!: string
        @Prop({default: () => []}) items!: Array<IFilterItem>

        currentValue: Array<any> = []

        get maxCount() {
            return 5
        }

        getChipOffsetClass(index: number) {
            return index > 0 ? 'chip-top-offset' : ''
        }

        get parentClass() {
            return this.currentValue.length > 0 ? 'disable-selection-input' : ''
        }

        get hideCount() {
            return this.isMobile ? 0 : this.currentValue.length - 1 - this.maxCount
        }

        get hint() {
            return this.hideCount > 0 ? `+${this.hideCount} others` : ''
        }

        get storeValues() {
            return store.getters['local_storage/currentFilters'][this.type] ?? []
        }

        get isActive() {
            return this.currentValue.length > 0
        }

        get activeClass() {
            return this.isActive ? 'active-border' : 'default-border'
        }

        removeByIndex(index: number) {
            this.currentValue.splice(index, 1)
            this.updateFilters()
        }

        @Watch('storeValues', {immediate: true})
        updateStoreValues() {
            this.currentValue = this.storeValues
        }

        updateFilters() {
            store.commit('local_storage/addFilter', {
                type: this.type,
                value: this.currentValue && this.currentValue.length ? this.currentValue : null,
            })
        }

        updateValue(value: Array<any>) {
            this.currentValue = value
            this.updateFilters()
        }
    }
