
    import {Component, Prop, Vue} from 'vue-property-decorator'
    import {IApplication} from '@/typings/IApplication'
    import RatingView from '@/components/Table/Feed/RatingView.vue'
    import {formatNumber} from '@/utils/utils'

    @Component({
        components: {
            RatingView,
        },
    })
    export default class AppTitle extends Vue {
        @Prop({required: true}) value!: IApplication
        @Prop({default: false}) rating!: boolean

        redirectToUrl() {
            window.open(this.value.url)
        }

        get ratingsCount() {
            return formatNumber(this.value.ratings)
        }
    }
