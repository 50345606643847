
    import {Component, Mixins} from 'vue-property-decorator'
    import TableControls from '@/components/Table/TableControls.vue'
    import TableFeedItemsGroup from '@/components/Table/Feed/TableFeedItemsGroup.vue'
    import TableContentView from '@/components/Table/Table/TableContentView.vue'
    import SignUpContainer from '@/components/SignUp/SignUpContainer.vue'
    import {ToggleState} from '@/typings/typings'
    import AdaptiveMixin from '@/components/Base/AdaptiveMixin'
    import TableContentViewMobile from '@/components/Table/Table/TableContentViewMobile.vue'
    import {store} from '@/store/store'
    import AppsView from '@/views/Dashboard/AppsView.vue'
    import DashboardScreenSelect from '@/views/Dashboard/DashboardScreenSelect.vue'
    import KeywordView from '@/views/Dashboard/KeywordView.vue'
    import TopChartsView from '@/views/Dashboard/TopChartsView.vue'

    @Component({
        components: {
            KeywordView,
            AppsView,
            TableContentViewMobile,
            TableContentView,
            TableFeedItemsGroup,
            TableControls,
            SignUpContainer,
            DashboardScreenSelect,
            TopChartsView,
        },
    })
    export default class Dashboard2 extends Mixins<AdaptiveMixin>(AdaptiveMixin) {

        states = ToggleState

        get currentState() {
            return store.getters['local_storage/toggleState']
        }
    }
