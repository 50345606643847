import {ITopChart} from '@/typings/ITopChart'

const ANDROID_CATEGORIES = {
    ALL: 'All',
    ANDROID_WEAR: 'Android Wear',
    APPLICATION: 'Application',
    ART_AND_DESIGN: 'Art & Design',
    AUTO_AND_VEHICLES: 'Auto & Vehicles',
    BEAUTY: 'Beauty',
    BOOKS_AND_REFERENCE: 'Books & Reference',
    BUSINESS: 'Business',
    COMICS: 'Comics',
    COMMUNICATION: 'Communication',
    DATING: 'Dating',
    EDUCATION: 'Education',
    ENTERTAINMENT: 'Entertainment',
    EVENTS: 'Events',
    FINANCE: 'Finance',
    FOOD_AND_DRINK: 'Food & Drink',
    HEALTH_AND_FITNESS: 'Health & Fitness',
    HOUSE_AND_HOME: 'House & Home',
    LIBRARIES_AND_DEMO: 'Libraries & Demo',
    LIFESTYLE: 'Lifestyle',
    APP_WALLPAPER: 'app Wallpaper',
    MAPS_AND_NAVIGATION: 'Maps & Navigation',
    MEDIA_AND_VIDEO: 'Media & Video',
    MEDICAL: 'Medical',
    MUSIC_AND_AUDIO: 'Music & Audio',
    NEWS_AND_MAGAZINES: 'News & Magazines',
    PARENTING: 'Parenting',
    PERSONALIZATION: 'Personalization',
    PHOTOGRAPHY: 'Photography',
    PRODUCTIVITY: 'Productivity',
    SHOPPING: 'Shopping',
    SOCIAL: 'Social',
    SPORTS: 'Sports',
    TOOLS: 'Tools',
    TRANSPORTATION: 'Transportation',
    TRAVEL_AND_LOCAL: 'Travel & Local',
    VIDEO_PLAYERS: 'Video Players & Editors',
    WEATHER: 'Weather',
    APP_WIDGETS: 'App Widgets',
    GAME: 'Game',
    FAMILY: 'Family',
    GAME_ACTION: 'Game Action',
    GAME_ADVENTURE: 'Game Adventure',
    GAME_ARCADE: 'Game Arcade',
    GAME_BOARD: 'Game Board',
    GAME_CARD: 'Game Card',
    GAME_CASINO: 'Game Casino',
    GAME_CASUAL: 'Game Casual',
    GAME_EDUCATIONAL: 'Game Educational',
    GAME_MUSIC: 'Game Music',
    GAME_PUZZLE: 'Game Puzzle',
    GAME_RACING: 'Game Racing',
    GAME_ROLE_PLAYING: 'Game Role Playing',
    GAME_SIMULATION: 'Game Simulation',
    GAME_SPORTS: 'Game Sports',
    GAME_STRATEGY: 'Game Strategy',
    GAME_TRIVIA: 'Game Trivia',
    GAME_WORD: 'Game Word',
    FAMILY_ACTION: 'Family Action & Adventure',
    FAMILY_BRAINGAMES: 'Family Brain Games',
    FAMILY_CREATE: 'Family Creativity',
    FAMILY_EDUCATION: 'Family Education',
    FAMILY_MUSICVIDEO: 'Family Music & Video',
    FAMILY_PRETEND: 'Family Pretend Play',
}

const APPSTORE_CATEGORIES = {
    '0': 'All',
    '6018': 'Books',
    '6000': 'Business',
    '6022': 'Catalogs',
    '6017': 'Education',
    '6016': 'Entertainment',
    '6015': 'Finance',
    '6023': 'Food & Drink',
    '6014': 'Games',
    '6013': 'Health & Fitness',
    '6012': 'Lifestyle',
    '6020': 'Medical',
    '6011': 'Music',
    '6010': 'Navigation',
    '6009': 'News',
    '6021': 'Newsstand',
    '6008': 'Photo & Video',
    '6007': 'Productivity',
    '6006': 'Reference',
    '6005': 'Social Networking',
    '6024': 'Shopping',
    '6004': 'Sports',
    '6003': 'Travel',
    '6002': 'Utilities',
    '6001': 'Weather',
    '7001': 'Game Action',
    '7002': 'Game Adventure',
    '7003': 'Game Casual',
    '7004': 'Game Board',
    '7005': 'Game Card',
    '7006': 'Game Casino',
    '7007': 'Game Dice',
    '7008': 'Game Educational',
    '7009': 'Game Family',
    '7010': 'Game Kids',
    '7011': 'Game Music',
    '7012': 'Game Puzzle',
    '7013': 'Game Racing',
    '7014': 'Game Role Playing',
    '7015': 'Game Simulation',
    '7016': 'Game Sports',
    '7017': 'Game Strategy',
    '7018': 'Game Trivia',
    '7019': 'Game Word',
}

export interface ITopChartsItemsGroup {
    type: string
    category: string
    items: Array<ITopChart>
}

export function getCategoryName(name: string | number) {
    return APPSTORE_CATEGORIES[String(name)] ?? ANDROID_CATEGORIES[String(name)] ?? ''
}

export function formatReleaseDate(value: ITopChart) {
    const releaseDate = value.application_cached_raw_datum?.raw_data?.release_date ?? null
    if (!releaseDate) {
        return ''
    }

    //@ts-ignore
    const days = Math.abs(Math.ceil((new Date() - new Date(releaseDate)) / 60 / 60 / 24 / 1000))

    if (days < 7) {
        return '1w'
    }

    if (days < 14) {
        return '2w'
    }

    if (days < 28) {
        return '1m'
    }

    if (days < 56) {
        return '2m'
    }

    if (days < 84) {
        return '3m'
    }

    return '>3m'
}