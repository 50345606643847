
import {Component, Mixins, Prop} from 'vue-property-decorator'
import IconPathsMixin from '@/components/Base/IconPathsMixin'
import AppTitle from '@/components/Table/Feed/AppTitle.vue'
import TableFavoriteIcon from '@/components/Table/Table/TableFavoriteIcon.vue'
import FormatHelper from '@/utils/FormatHelper'
import AddToFavoritesButton from "@/components/Table/Feed/AddToFavoritesButton.vue";
import {store} from "@/store/store";
import {IMetricsByApplicationKeyword} from '@/typings/IMetricsByApplicationKeyword'
import DetailsTopAppsByKeyword from '@/views/Details/DetailsTopAppsByKeyword.vue'
import {IApplicationCachedRawData} from '@/typings/IApplicationAdditionalData'
import Requester from '@/utils/Requester'

@Component({
    components: {
        AppTitle,
        TableFavoriteIcon,
        AddToFavoritesButton,
        DetailsTopAppsByKeyword
    },
})
export default class KeywordsTableContentItemMobile extends Mixins<IconPathsMixin, FormatHelper>(IconPathsMixin, FormatHelper) {
    @Prop({required: true}) item!: IMetricsByApplicationKeyword
    isExpand = false

    showDialog = false
    currentKeyword: string | null = null
    currentAppsByKeyword: Array<IApplicationCachedRawData> | null = null

    get isLogged() {
        return store.getters['local_storage/isLogged']
    }

    get color() {
        return this.isExpand ? 'primary' : ''
    }

    get icon() {
        return this.isExpand ? 'mdi-menu-up' : 'mdi-menu-down'
    }

    redirectToUrl() {
        window.open(this.item.application!.url)
    }

    async showTopApps(metrics: IMetricsByApplicationKeyword) {
        this.showDialog = true

        if (this.currentAppsByKeyword) {
            return
        }

        this.currentKeyword = metrics.keyword!.keyword
        this.currentAppsByKeyword = (await Requester.get<Array<IApplicationCachedRawData>>(encodeURI(`top-apps/${metrics.application!.os}/${this.currentKeyword}`)))
    }

    async redirectToDescription() {
        if (!this.isLogged) {
            this.$store.commit('local_variables/loginPopup', true)
            return
        }

        await store.dispatch('local_variables/loadPage', `/details/${this.item.application!.id}`)
    }
}
