
    import {Component, Vue} from 'vue-property-decorator'
    import {store} from '@/store/store'

    @Component
    export default class PageNotFound extends Vue {

        async redirectToMainPage() {
            await store.dispatch('local_variables/loadPage', '/')
        }
    }
