
    import {Component, Mixins, Prop} from 'vue-property-decorator'
    import {IApplication} from '@/typings/IApplication'
    import {formatChange, formatDateMonthYear, formatNumber, getChangeColor} from '@/utils/utils'
    import IconPathsMixin from '@/components/Base/IconPathsMixin'

    @Component
    export default class AppInfo extends Mixins<IconPathsMixin>(IconPathsMixin) {
        @Prop({required: true}) value!: IApplication

        get downloads() {
            return formatNumber(this.value.downloads)
        }

        get downloadsChangeColor() {
            return getChangeColor(this.value.downloads_change)
        }

        get downloadsChange() {
            return formatChange(this.value.downloads_change)
        }

        get revenue() {
            return formatNumber(this.value.revenue)
        }

        get revenueChange() {
            return formatChange(this.value.revenue_change)
        }

        get revenueChangeColor() {
            return getChangeColor(this.value.revenue_change)
        }

        get firstReleaseDate() {
            return formatDateMonthYear(this.value.release_date)
        }

        get lastUpdateDate() { // TODO inj735 months count
            return formatDateMonthYear(this.value.updated_date)
        }

        get iconStyle() {
            return this.value.os === 'ios' ? {'margin-top': '-1px'} : {}
        }

        get icon() {
            switch (this.value.os) {
                case 'android':
                    return this.icons.androidIcon
                case 'ios':
                    return this.icons.iosIcon
            }

            throw new Error('Cant recognize icon!')
        }

        redirectToUrl() {
            window.open(this.value.url)
        }
    }
