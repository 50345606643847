
    import {Component, Mixins, Prop} from 'vue-property-decorator'
    import FitSize from '@/utils/FitSize.vue'
    import {IFooterContent} from '@/components/Footer/typings'
    import IconPathsMixin from '@/components/Base/IconPathsMixin'

    @Component({
        components: {
            FitSize,
        },
    })
    export default class FooterMobile extends Mixins<IconPathsMixin>(IconPathsMixin) {
        @Prop({required: true}) contents!: IFooterContent
    }
