
    import axios from 'axios'
    import {Component, Prop, Vue} from 'vue-property-decorator'

    @Component
    export default class ImageLoader extends Vue {
        @Prop({required: true}) url!: string
        image: string | null = null

        loaded = false
        errorOccured = false
        tryCount = 10

        forceStopLoad = false

        async sleep(ms: number) {
            await new Promise((resolve => setTimeout(resolve, ms)))
        }

        async load() {
            this.loaded = false

            for (let i = 0; i < this.tryCount; i++) {
                if (this.forceStopLoad) {
                    return
                }
                try {
                    const config = {url: this.url, method: 'get', responseType: 'blob'}
                    const response = await axios.request(config)
                    this.image = URL.createObjectURL(response.data)
                    this.loaded = true
                    return
                } catch (e) {
                    if (e.response?.status === 404) {
                        this.errorOccured = true
                        this.loaded = true
                        return null
                    }

                    if (!e.response || e.response?.status === 429 || e.response?.status === 403) {
                        i--
                    }

                    if (i > this.tryCount * 4) {
                        return null
                    }

                    await this.sleep(400 + Math.floor(Math.random() * 1000))
                }
            }

            this.loaded = true
            this.errorOccured = true
        }

        async mounted() {
            await this.load()
        }

        beforeDestroy() {
            this.forceStopLoad = true
        }
    }
