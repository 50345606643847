
    import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
    import FitSize from '@/utils/FitSize.vue'
    import SignUpCard from '@/components/SignUp/Cards/SignUpCard.vue'
    import BillingDetailsCard from '@/components/SignUp/Cards/BillingDetailsCard.vue'

    interface IHeaderStep {
        title: string
    }

    @Component({
        components: {BillingDetailsCard, FitSize, SignUpCard},
    })
    export default class SignUpContainer extends Vue {
        @Prop({default: 'Sing Up'}) title!: string
        @Prop({default: 1}) defaultState!: number

        signUpState = 1

        @Watch('defaultState', {immediate: true})
        onDefaultStateUpdate() {
            this.signUpState = this.defaultState
        }

        headerSteps: Array<IHeaderStep> = [
            {
                title: 'Registration',
            },
            {
                title: 'Payment',
            }
        ]

        dividerClass(value: boolean) {
            return value ? 'active' : ''
        }
    }
