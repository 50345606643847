export enum CHECK_STATUSES {
    LOADING = -1, // Frontend only
    HAS_PAYMENT,
    NO_PAYMENT,
    LAST_PAYMENT_STATUS_FAILED,
    LAST_PAYMENT_STATUS_WAIT,
    UNHANDLED,
}

export interface ICheckPayment {
    status: CHECK_STATUSES
    url?: string | null
}