import {Component, Vue} from 'vue-property-decorator'
import {IRequestMeta} from '@/typings/IRequestMeta'
import {needLoadByScroll} from '@/utils/utils'

@Component
export default class ItemLoaderMixin<TItem> extends Vue {
    items: Array<TItem> = []

    fullReload = true
    nextLoad = false

    currentPage = 1

    requestMeta: IRequestMeta | null = null

    subscriptionError = false

    get recordsCount() {
        return this.requestMeta?.total_items ?? 0
    }

    async handleScroll() {
        if (needLoadByScroll()) {
            await this.loadNext()
        }
    }

    async loadNext() {
        if (this.nextLoad || this.fullReload) {
            return
        }

        if (this.requestMeta && this.currentPage >= this.requestMeta.total_pages) {
            return
        }

        if (this.subscriptionError) {
            return
        }

        this.nextLoad = true
        this.currentPage++
        this.items = [
            ...this.items,
            ...(await this.loadItems())?.data ?? [],
        ]

        this.nextLoad = false
    }

    async reload() {
        this.currentPage = 1
        this.fullReload = true
        this.items = (await this.loadItems())?.data ?? []
        this.fullReload = false
    }

    async requestItems(): Promise<{ data: Array<TItem>, meta: IRequestMeta }> {
        throw new Error('Not implemented!')
    }

    async loadItems() {
        try {
            const response = await this.requestItems()
            this.requestMeta = response.meta

            return response
        } catch (e) {
            this.subscriptionError = true
        }
    }

    async mounted() {
        window.addEventListener('scroll', this.handleScroll)
        await this.reload()
    }

    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll)
    }
}