
    import {Component, Mixins} from 'vue-property-decorator'
    import FitSize from '@/utils/FitSize.vue'
    import InlineSvg from 'vue-inline-svg'
    import IconPathsMixin from '@/components/Base/IconPathsMixin'
    import {ItemsContent} from '@/components/Base/ItemsContent'
    import TableFavoriteIcon from '@/components/Table/Table/TableFavoriteIcon.vue'
    import FormatHelper from '@/utils/FormatHelper'
    import {IApplication} from "@/typings/IApplication";
    import SubscriptionErrorText from '@/components/Table/SubscriptionErrorText.vue'
    import {store} from '@/store/store'
    import {IMetricsByApplicationKeyword} from '@/typings/IMetricsByApplicationKeyword'
    import DetailsTopAppsByKeyword from '@/views/Details/DetailsTopAppsByKeyword.vue'
    import {IApplicationCachedRawData} from '@/typings/IApplicationAdditionalData'
    import Requester from '@/utils/Requester'
    import {IMetricsResponse} from '@/typings/IMetricsResponse'

    @Component({
        components: {
            FitSize,
            InlineSvg,
            TableFavoriteIcon,
            SubscriptionErrorText,
            DetailsTopAppsByKeyword,
        },
    })
    export default class KeywordsTable extends Mixins<IconPathsMixin, ItemsContent<IMetricsByApplicationKeyword>, FormatHelper>(FormatHelper, IconPathsMixin, ItemsContent) {
        headers = [
            {
                text: 'OS',
                value: 'os',
            },
            {
                text: 'Name',
                value: 'name',
            },
            {
                text: 'Keyword',
                value: 'keyword',
            },
            {
                text: 'Installs',
                value: 'installs',
            },
            {
                text: 'Rank',
                value: 'rank',
            },
            {
                text: 'TOP 3 Vacant',
                value: 'top_3_vacant',
            },
        ]

        showDialog = false
        currentKeyword: string | null = null
        appsByKeyword: Record<string, Array<IApplicationCachedRawData>> = {}
        currentAppsByKeyword: Array<IApplicationCachedRawData> = []

        get isLogged() {
            return store.getters['local_storage/isLogged']
        }

        async showTopApps(metrics: IMetricsByApplicationKeyword) {
            this.currentAppsByKeyword = []
            this.showDialog = true
            this.currentKeyword = metrics.keyword!.keyword

            if (!this.appsByKeyword[this.currentKeyword]) {
                this.appsByKeyword[this.currentKeyword] =
                    (await Requester.get<Array<IApplicationCachedRawData>>(encodeURI(`top-apps/${metrics.application!.os}/${this.currentKeyword}`)))
            }

            this.currentAppsByKeyword = this.appsByKeyword[this.currentKeyword]
        }

        redirectToUrl(value: IApplication) {
            window.open(value.url)
        }

        getFillColor() {
            return '#D1D1D1'
        }

        async onApplicationClick(item: IApplication) {
            if (!this.isLogged) {
                this.$store.commit('local_variables/loginPopup', true)
                return
            }

            await store.dispatch('local_variables/loadPage', `/details/${item.id}`)
        }
    }
