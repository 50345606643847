
    import {Component, Mixins, Prop} from 'vue-property-decorator'
    import AdaptiveMixin from '@/components/Base/AdaptiveMixin'
    import {IPurchaseObject} from '@/typings/IApplicationAdditionalData'

    @Component
    export default class DetailsTopPurchaseInApp extends Mixins<AdaptiveMixin>(AdaptiveMixin) {
        @Prop({required: true}) value!: string | Array<IPurchaseObject> | null
        @Prop({required: true}) os!: 'ios' | 'android'
        @Prop({required: true}) loaded!: boolean

        formatDuration(duration: string | null) {
            switch (duration) {
                case null:
                case 'one-time':
                    return 'One time'
                case 'P1M':
                    return 'Monthly'
                case 'P7D':
                    return 'Weekly'
                case 'P2M':
                    return '2 Month'
                case 'P3M':
                    return 'Quarter'
                case 'P6M':
                    return 'Half year'
                case 'P1Y':
                    return 'Yearly'
            }

            return duration
        }

        get androidValue() {
            return this.value ?? 'No Purchases'
        }
    }
