
    import {Component, Mixins, Prop} from 'vue-property-decorator'
    import AdaptiveMixin from '@/components/Base/AdaptiveMixin'

    @Component
    export default class DetailsDescription extends Mixins<AdaptiveMixin>(AdaptiveMixin) {
        @Prop({required: true}) value!: string | null
        @Prop({required: true}) loaded!: boolean

        currentIndex = 0

        get formattedValue() {
            return this.value?.split('<br>')
                .map(v => v.replaceAll(/<[\/\w\s\d=\[\]"]+>/g, ''))
        }

        get height() {
            return this.isMobile ? 440 : 640
        }
    }
