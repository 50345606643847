import {Component, Mixins} from 'vue-property-decorator'
import Reference from '../../helpers/reference/reference.json'
import {IApplication} from '@/typings/IApplication'
import {formatChange, formatDateMonthYear, getChangeColor} from './utils'
import { formatNumber } from './utils';
import IconPathsMixin from '@/components/Base/IconPathsMixin'
import {IAdObject} from '@/typings/IApplicationAd'

@Component
export default class FormatHelper extends Mixins<IconPathsMixin>(IconPathsMixin) {
    formatDateMonthYear(value: string) {
        return formatDateMonthYear(value)
    }

    formatChange(value: number | null) {
        return formatChange(value)
    }

    formatRating(value: number | null) {
        return (value ?? 0).toFixed(2)
    }

    getCategories(categories) {
        return categories
            .map(c => c.category_id)
            .filter((value, index, self) => self.indexOf(value) === index);
    }

    getTrafficCost(item) {
        const categories = this.getCategories(item.application_categories);
        let trafficCost = 0;
        categories.forEach(cat => {
            let cost = 0;
            if (Reference.costs[cat]) {
                cost = item.downloads * Reference.costs[cat].value;
                if (cost > trafficCost) {
                    trafficCost = cost;
                }
            }
        })

        return trafficCost;
    }

    getCategoriesNames(value: IApplication) {
        return value.application_categories?.map(v => Reference.categories[v.category_id]).join(', ') ?? ''
    }

    getROAS(item) {
        const trafficCost = this.getTrafficCost(item)
        return (trafficCost ? item.revenue / trafficCost : 0).toFixed(2);
    }

    getAdObject(value: IApplication) {
        if (!value.application_ads || !value.application_ads.length) {
            return null
        }

        const rawAdObject = value.application_ads[value.application_ads.length - 1].ad_object

        if (!rawAdObject) {
            return null
        }

        if (typeof rawAdObject === 'string') {
            return JSON.parse(rawAdObject) as IAdObject
        }

        return rawAdObject
    }

    getAdUrl(value: IApplication) {
        const adObject = this.getAdObject(value)
        return adObject?.preview_url ?? adObject?.thumb_url ?? adObject?.creative_url
    }

    formatNumber(value: number) {
        return formatNumber(value)
    }

    getChangeColor(changeValue: number) {
        return getChangeColor(changeValue)
    }

    getAppIcon(value: IApplication) {
        switch (value.os) {
            case 'android':
                return this.icons.androidIcon
            case 'ios':
                return this.icons.iosIcon
        }

        throw new Error('Cant recognize icon!')
    }
}