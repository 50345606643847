
import {Component, Mixins, Prop} from 'vue-property-decorator'
    import FilterItem from '@/components/Table/FilterItem.vue'
    import AdaptiveMixin from '@/components/Base/AdaptiveMixin'
    import FilterItemMultiple from '@/components/Table/FilterItemMultiple.vue'

    @Component({
        components: {FilterItemMultiple, FilterItem},
    })
    export default class TableRecordsCount extends Mixins<AdaptiveMixin>(AdaptiveMixin) {
        @Prop({required: true}) recordsCount!: number | null

        get totalItems() {
            return this.recordsCount ?? 0
        }
    }
