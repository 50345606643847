
    import {Component, Vue} from 'vue-property-decorator'
    import SingInDialog from '@/components/Modals/SingInDialog.vue'

    @Component({
        components: {
            SingInDialog,
        },
    })
    export default class SignInButton extends Vue {
        openDialog() {
            this.$store.commit('local_variables/loginPopup', true)
        }
    }
