
    import {Component, Vue} from 'vue-property-decorator'

    @Component
    export default class TopChartsTimeFilter extends Vue {
        oldDate: Date | null = null
        date: Date | null = null
        menu = false

        get formattedDate() {
            return new Date(this.date!).toString('yyyy-MM-dd')
        }

        get maxDate() {
            const date = new Date()
            date.setDate(date.getDate() - 1)
            return date.toISOString()
        }

        cancel() {
            this.menu = false
            this.date = this.oldDate
        }

        updateDate() {
            this.menu = false
            this.$emit('update:date', this.date)
            this.oldDate = this.date
        }

        mounted() {
            this.date = this.maxDate
            this.updateDate()
        }
    }
