export function emailValidation(v?: string | null) {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return v && pattern.test(v)
}

export function countValidation(count: number, v?: string | null) {
    return v && v.length >= count
}

export function sameValidation(v1?: string | null, v2?: string | null) {
    return v1 && v2 && v1 === v2
}