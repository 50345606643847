const countFormat = [
    '',
    'k',
    'm',
    'b',
]

export function formatCount(value: number | null) {
    if (!value) {
        return ''
    }

    if (value > 100) {
        return `+99`
    }

    return value
}

export function getChangeColor(value: number | null) {
    if (!value) {
        return '#252525'
    }

    return value > 0 ? '#00AF91' : '#C70039'
}

export function formatDateMonthYear(value: string) {
    //@ts-ignore
    return new Date(value).toString('yyyy-MM')
}

export function formatChange(value: number | null) {
    if (!value) {
        return null
    }

    return value > 0 ? `+${value}` : `${value}`
}

export function formatNumber(value: number, useLowBounds = true) {
    if (value < 5000 && useLowBounds) {
        return '<5k'
    }

    let currentIndex = 0

    while (value >= 1000) {
        currentIndex++
        value /= 1000
    }

    return `${Math.round(value * 10) / 10}${countFormat[currentIndex]}`
}

export function formatIntegerToKilos(number) {
    if (number < 1000) return number;

    let value = (number / 1000);
    if (Number.isInteger(value)) {
        return value + 'K';
    } else {
        return Math.round(value * 10) / 10 + 'K';
    }
}

export function needLoadByScroll() {
    const currentTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight
    const offset = document.documentElement.offsetHeight - 600

    return currentTop >= offset
}