
    import {Component, Mixins, Prop} from 'vue-property-decorator'
    import RatingView from '@/components/Table/Feed/RatingView.vue'
    import {IApplicationTopApps} from '@/typings/IApplicationTopApps'
    import {makeUrl} from '@/views/Details/utils'
    import ImageLoader from '@/utils/ImageLoader.vue'
    import AdaptiveMixin from '@/components/Base/AdaptiveMixin'
    import {formatNumber} from '@/utils/utils'

    @Component({
        components: {
            RatingView,
            ImageLoader
        },
    })
    export default class DetailsTopAppItem extends Mixins<AdaptiveMixin>(AdaptiveMixin) {
        @Prop({required: true}) item!: IApplicationTopApps
        @Prop({required: true}) place!: number

        currentIndex = 0

        get screenshots() {
            return (this.rawData.screenshots ?? []).filter(v => v).slice(0, 3)
        }

        get formattedRatingsCount() {
            return formatNumber(this.item.raw_data.ratings_count, false)
        }

        get formattedRating() {
            const rating = this.rawData.rating

            if (typeof rating === 'number') {
                return Math.round(rating * 10) / 10
            }

            return rating
        }

        get rawData() {
            return this.item.raw_data
        }

        redirectToUrl() {
            if (!this.item) {
                return
            }

            window.open(makeUrl(this.item.store_app_id, this.item.os))
        }
    }
