
    import {Component, Mixins, Prop} from 'vue-property-decorator'
    import IconPathsMixin from '@/components/Base/IconPathsMixin'
    import AppTitle from '@/components/Table/Feed/AppTitle.vue'
    import TableFavoriteIcon from '@/components/Table/Table/TableFavoriteIcon.vue'
    import FormatHelper from '@/utils/FormatHelper'
    import AddToFavoritesButton from '@/components/Table/Feed/AddToFavoritesButton.vue'
    import {store} from '@/store/store'
    import DetailsTopAppsByKeyword from '@/views/Details/DetailsTopAppsByKeyword.vue'
    import {IApplicationCachedRawData} from '@/typings/IApplicationAdditionalData'
    import {ITopChart} from '@/typings/ITopChart'
    import {makeUrl} from '@/views/Details/utils'
    import {getCategoryName, formatReleaseDate} from '@/components/TopChartsTable/utils'

    @Component({
        components: {
            AppTitle,
            TableFavoriteIcon,
            AddToFavoritesButton,
            DetailsTopAppsByKeyword,
        },
    })
    export default class TopChartsTableContentItemMobile extends Mixins<IconPathsMixin, FormatHelper>(IconPathsMixin, FormatHelper) {
        @Prop({required: true}) item!: ITopChart
        isExpand = false

        showDialog = false
        currentKeyword: string | null = null
        currentAppsByKeyword: Array<IApplicationCachedRawData> | null = null

        get isLogged() {
            return store.getters['local_storage/isLogged']
        }

        get color() {
            return this.isExpand ? 'primary' : ''
        }

        get icon() {
            return this.isExpand ? 'mdi-menu-up' : 'mdi-menu-down'
        }

        formatReleaseDate(value: ITopChart) {
            return formatReleaseDate(value)
        }

        formatCategoryName(value: string) {
            return getCategoryName(value)
        }

        formatType(type: 'free' | 'grossing') {
            switch (type) {
                case 'free':
                    return 'Installs'
                case 'grossing':
                    return 'Grossing'
            }
        }

        get applicationIconRuleUrl() {
            return this.item.application_cached_raw_datum.raw_data.icon
        }

        redirectToUrl() {
            window.open(makeUrl(this.item.store_app_id, this.item.os))
        }

        get appIcon() {
            switch (this.item.os) {
                case 'android':
                    return this.icons.androidIcon
                case 'ios':
                    return this.icons.iosIcon
            }

            throw new Error('Cant recognize icon!')
        }
    }
