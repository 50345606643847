import router from '@/router'

export interface ILocalVariablesModule {
    loginPopup: boolean
}

export default {
    namespaced: true,
    state: {
        loginPopup: false,
    } as ILocalVariablesModule,
    getters: {
        loginPopup: state => state.loginPopup ?? false
    },
    mutations: {
        loginPopup(state, value: boolean) {
            state.loginPopup = value
        },
    },
    actions: {
        async loadPage({state, commit}, path: string) {
            commit('loginPopup', false)
            window.scrollTo({top: 0})
            if (router.currentRoute.path === path) {
                location.reload()
            }

            await router.push(path)
        },
    }
}