export type TOperatingSystem = 'ios' | 'android'

export enum DataViewType {
    FEED,
    TABLE,
}

export enum ToggleState {
    Apps,
      Keywords,
    TopCharts
}