import {store} from '@/store/store'
import Requester from '@/utils/Requester'

export interface IFavoritesStorageModule {
    favoritesIds: Array<number> | null
}

function getUserId() {
    return store.getters['local_storage/loggedUser']?.user_id ?? null
}

export default {
    namespaced: true,
    state: {
        favoritesIds: [],
    } as IFavoritesStorageModule,
    getters: {
        favoritesIds: state => state.favoritesIds ?? [],
    },
    mutations: {
        updateFavoritesList(state, list: Array<number>) {
            state.favoritesIds = list
        },
    },
    actions: {
        async loadFavoritesIds({commit}) {
            const userId = getUserId()

            if (!userId) {
                return
            }

            const {data} = await Requester.get('favorites/ids', {user_id: userId})
            commit('updateFavoritesList', data)
        },
        async addToFavorites({state, commit}, id: number) {
            commit('updateFavoritesList', [...state.favoritesIds, id])
            await Requester.post('favorites', {id}, {user_id: getUserId()})
        },
        async removeFromFavorites({state, commit}, id: number) {
            commit('updateFavoritesList', state.favoritesIds.filter(favoriteId => favoriteId !== id))
            await Requester.delete(`favorites/${id}`, {user_id: getUserId()})
        }
    }
}