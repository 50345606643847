
import {Component, Mixins} from 'vue-property-decorator'
import InlineSvg from 'vue-inline-svg'
import IconPathsMixin from '@/components/Base/IconPathsMixin'
import {FavoriteButtonMixin} from '@/mixins/FavoriteButtonMixin'

@Component({
    components: {
        InlineSvg,
    },
})
export default class AddToFavoritesButton extends Mixins<IconPathsMixin, FavoriteButtonMixin>(IconPathsMixin, FavoriteButtonMixin) {

    get text() {
        return this.isFavorite ? 'Remove from Favorites' : 'Add to Favorites'
    }

    get style() {
        return {
            'border-color': this.isFavorite ? '#FFF3E1' : '#E1F1FF',
            'background-color': this.isFavorite ? '#FFFCF1' : '#FCFDFF',
        }
    }
}
