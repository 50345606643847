
    import {Component, Mixins} from 'vue-property-decorator'
    import TableFeedItem from '@/components/Table/Feed/TableFeedItem.vue'
    import FitSize from '@/utils/FitSize.vue'
    import {ItemsContent} from '@/components/Base/ItemsContent'
    import AdaptiveMixin from '@/components/Base/AdaptiveMixin'
    import SubscriptionErrorText from '@/components/Table/SubscriptionErrorText.vue'
    import {IApplication} from '@/typings/IApplication'

    @Component({
        components: {FitSize, TableFeedItem, SubscriptionErrorText},
    })
    export default class TableFeedItemsGroup extends Mixins<ItemsContent<IApplication>, AdaptiveMixin>(ItemsContent, AdaptiveMixin) {
    }
