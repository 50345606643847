import Reference from '@/../helpers/reference/reference.json'
import {IApplication} from '@/typings/IApplication'

export type GroupType = 'array' | null

export interface ISortingValue {
    field: keyof IApplication
    value: 'DESC' | 'ASC'
}

export interface ISortingItem {
    text: string
    field: keyof IApplication
}

export interface IFilterItem {
    text: string
    value: string | number
}

export interface IFilterGroup {
    text: string,
    value: string | number
    type?: GroupType
    defaultValue?: IFilterItem
    multiselect?: boolean
    items: Array<IFilterItem>
}

export const sortings: Array<ISortingItem> = [
    {
        field: 'downloads_change',
        text: 'Most Downloads Growth',
    },
    {
        field: 'revenue_change',
        text: 'Most Revenue Growth',
    }
]

export const filters: Array<IFilterGroup> = [
    {
        text: 'Os',
        value: 'os',
        items: [
            {text: 'All', value: 'All'},
            {text: 'IOS', value: 'ios'},
            {text: 'Android', value: 'android'},
        ],
    },
    {
        text: 'Type',
        value: 'type',
        items: [
            {text: 'All', value: 'All'},
            {text: 'Application', value: 'app'},
            {text: 'Game', value: 'game'},
        ],
    },
    {
        text: 'Ad',
        value: 'ad',
        items: [
            {text: 'All', value: 'All'},
            {text: 'Yes', value: 1},
            {text: 'No', value: 0},
        ],
    },
    /*{
        text: 'Removed',
        value: 'removed',
        items: [
            {text: 'All', value: 'All'},
            {text: 'Yes', value: 1},
            {text: 'No', value: 0},
        ]
    },*/
    {
        text: 'Categories',
        value: 'categories',
        type: 'array',
        items: Object.keys(Reference.categories).map(key => {
            if (key == '0') {
                return null
            }

            return {
                text: Reference.categories[key],
                value: key,
            }
        }).filter(value => value) as Array<IFilterItem>,
    },
    {
        text: 'Release',
        value: 'release',
        items: [
            {text: 'All', value: 'All'},
            {text: '<1 Month', value: '30'},
            {text: '<3 Months', value: '90'},
            {text: '<6 Months', value: '180'},
            {text: '<12 Months', value: '360'},
            {text: '<24 Months', value: '720'},
        ],
    },
    {
        text: 'Last Update',
        value: 'update',
        items: [
            {text: 'All', value: 'All'},
            {text: '<1 Month', value: '30'},
            {text: '<3 Months', value: '90'},
            {text: '<6 Months', value: '180'},
            {text: '<12 Months', value: '360'},
            {text: '<24 Months', value: '720'},
        ],
    },
    {
        text: 'All Ratings',
        value: 'ratings',
        type: 'array',
        items: [
            {text: '<100', value: '<100'},
            {text: '100-500', value: '100-500'},
            {text: '500-1000', value: '500-1000'},
            {text: '>1000', value: '>1000'},
        ],
    },
    {
        text: 'Revenue',
        value: 'revenue',
        type: 'array',
        items: [
            {text: '$5-20k', value: '5000-20000'},
            {text: '$20-50k', value: '20000-50000'},
            {text: '$50-100k', value: '50000-100000'},
            {text: '$100-500k', value: '100000-500000'},
            {text: '>$500k', value: '>500000'},
        ],
    },
    {
        text: 'Downloads',
        value: 'downloads',
        type: 'array',
        items: [
            {text: '5-10k', value: '5000-10000'},
            {text: '10-50k', value: '10000-50000'},
            {text: '50-100k', value: '50000-100000'},
            {text: '100-250k', value: '100000-250000'},
            {text: '250-500k', value: '250000-500000'},
            {text: '>500k', value: '>500000'},
        ],
    },
    {
        text: 'Group',
        value: 'group',
        items: [
            {text: 'All', value: 'All'},
            {text: 'List', value: 'list'},
            {text: 'Brands', value: 'brands'},
        ],
    },
]
