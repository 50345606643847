
    import {Component, Vue} from 'vue-property-decorator'
    import {store} from '@/store/store'

    @Component
    export default class SubscriptionErrorText extends Vue {
        get logged() {
            return this.$store.getters['local_storage/isLogged']
        }

        async openSubscription() {
            if (this.logged) {
                await store.dispatch('local_variables/loadPage', `/subscription`)
                return
            }

            this.$store.commit('local_variables/loginPopup', true)
        }
    }
