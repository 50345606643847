
    import {Component, Vue, Watch} from 'vue-property-decorator'
    import FitSize from '@/utils/FitSize.vue'
    import Requester from '@/utils/Requester'

    interface IRecoveryRequest {
        email?: string
    }

    @Component({
        components: {
            FitSize,
        },
    })
    export default class ForgetPassword extends Vue {
        data: IRecoveryRequest = {}

        loading = false

        alert = false
        handleTimeout = -1
        errorMessages = {}

        alertType: 'error' | 'info' = 'error'

        alertMessage: string | null = null

        @Watch('data.email')
        onEmailUpdate() {
            this.errorMessages = {}
        }

        async sendRecoveryRequest() {
            if (!this.data.email) {
                this.showErrorAlert('Email is empty!')
                return
            }

            this.errorMessages = {}
            this.loading = true

            const response = await Requester.sendRecoveryRequest(
                this.data.email!,
            )
            this.loading = false

            const data = response?.response?.data

            if (data?.error_messages) {
                this.errorMessages = data.error_messages
                return
            }

            if (response?.status === 200) {
                this.showInfoAlert(response?.data)
                return
            }

            this.showErrorAlert('Error occurred')
        }

        showInfoAlert(message: string) {
            this.alertMessage = message
            this.alertType = 'info'
            this.showAlert()
        }

        showErrorAlert(message: string) {
            this.errorMessages.email = message
            this.alertMessage = message
            this.alertType = 'error'
            this.showAlert()
        }

        showAlert() {
            this.alert = true
            window.clearTimeout(this.handleTimeout)
            this.handleTimeout = window.setTimeout(() => this.alert = false, 5000)
        }
    }
