
    import {Component, Mixins} from 'vue-property-decorator'
    import InlineSvg from 'vue-inline-svg'
    import IconPathsMixin from '@/components/Base/IconPathsMixin'
    import {FavoriteButtonMixin} from '@/mixins/FavoriteButtonMixin'

    @Component({
        components: {
            InlineSvg
        }
    })
    export default class TableFavoriteIcon extends Mixins<IconPathsMixin, FavoriteButtonMixin>(IconPathsMixin, FavoriteButtonMixin) {
    }
