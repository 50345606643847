import axios from 'axios'
import {store} from '@/store/store'

function getAccessToken() {
    return store.getters['local_storage/accessToken']
}

function getHeaders() {
    return {
        Authorization: `Bearer ${getAccessToken()}`,
    }
}

export default class Requester {
    static async auth(login: string, password: string) {
        try {
            const data = (await axios.post(process.env.VUE_APP_NODE_SERVER + '/api/auth', {
                login,
                password,
            })).data

            return data.token
        } catch (e) {
            return null
        }
    }

    static async registration(email: string, password: string, repeatPassword: string) {
        try {
            return await axios.post(
                process.env.VUE_APP_NODE_SERVER + '/api/registration',
                {
                    password,
                    email,
                    repeat_password: repeatPassword,
                },
            )
        } catch (e) {
            return e
        }
    }

    static async sendRecoveryRequest(login: string) {
        try {
            return await axios.post(
                process.env.VUE_APP_NODE_SERVER + '/api/recovery-request',
                {
                    login,
                },
            )
        } catch (e) {
            return e
        }
    }

    static async recoverPassword(password: string, repeatPassword: string, key: string) {
        try {
            return await axios.post(
                process.env.VUE_APP_NODE_SERVER + '/api/recover-password',
                {
                    key,
                    password,
                    repeat_password: repeatPassword,
                },
            )
        } catch (e) {
            return e
        }
    }

    static async get<T extends any>(endpoint: string, params: any = {}) {
        return (await axios.get(`${process.env.VUE_APP_NODE_SERVER}/api/${endpoint}`, {
            headers: getHeaders(),
            params,
        })).data as T
    }

    static async post<T extends any>(endpoint: string, data: any, params: any = {}) {
        return (await axios.post(
            `${process.env.VUE_APP_NODE_SERVER}/api/${endpoint}`,
            data,
            {
                headers: getHeaders(),
                params,
            })).data as T
    }

    static async delete(endpoint: string, params: any = {}) {
        return (await axios.delete(`${process.env.VUE_APP_NODE_SERVER}/api/${endpoint}`, {
            headers: getHeaders(),
            params,
        })).data
    }
}