
    import {Component, Mixins, Model, Prop} from 'vue-property-decorator'
    import IconPathsMixin from '../../components/Base/IconPathsMixin'
    import DetailsTopAppItem from '@/views/Details/DetailsTopAppItem.vue'
    import AdaptiveMixin from '@/components/Base/AdaptiveMixin'
    import {IApplicationCachedRawData} from '@/typings/IApplicationAdditionalData'

    @Component({
        components: {
            DetailsTopAppItem
        }
    })
    export default class DetailsTopAppsByKeyword extends Mixins<IconPathsMixin, AdaptiveMixin>(IconPathsMixin, AdaptiveMixin) {
        @Model('update') show!: boolean
        @Prop({required: true}) items!: Array<IApplicationCachedRawData>
        @Prop({required: true}) keyword!: string

        get availableItems() {
            return this.items.filter(v => v)
        }

        get width() {
            return this.isMobile ? 320 : 480
        }
    }
