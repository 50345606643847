import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const colors = {
    primary: '#5075F6'
}

export default new Vuetify({
    theme: {
        dark: false,
        themes: {
            light: colors,
        },
        options: {
            customProperties: true,
        },
    },
})