
    import {Component, Mixins, Prop} from 'vue-property-decorator'
    import TableSearch from '@/components/Table/TableSearch.vue'
    import FitSize from '@/utils/FitSize.vue'
    import TableToggleButtons from '@/components/Table/TableToggleButtons.vue'
    import TableFiltersCard from '@/components/Table/TableFiltersCard.vue'
    import AdaptiveMixin from '@/components/Base/AdaptiveMixin'
    import {store} from '@/store/store'
    import TableRecordsCount from "@/components/Table/TableRecordsCount.vue";

    @Component({
        components: {TableFiltersCard, TableRecordsCount, TableSearch, TableToggleButtons, FitSize},
    })
    export default class TableControls extends Mixins<AdaptiveMixin>(AdaptiveMixin) {
        @Prop({default: true}) hasFilters!: boolean
        @Prop({default: () => null}) recordsCount!: number | null

        get showFilters() {
            return store.getters['local_storage/filtersOpened']
        }
    }
