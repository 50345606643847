
    import {Component, Prop, Vue} from 'vue-property-decorator'
    import {ISortingItem} from '@/components/Table/data'
    import {store} from '@/store/store'

    @Component
    export default class SortingItem extends Vue {
        @Prop({required: true}) item!: ISortingItem

        get upsideDownClass() {
            return this.currentState === 'DESC' ? 'upside-down' : ''
        }

        get activeClass() {
            return this.currentState ? 'active' : ''
        }

        get currentState() {
            const currentSort = store.getters['local_storage/currentSort']

            return currentSort && currentSort.field === this.item.field ? currentSort.value : null
        }

        nextState() {
            if (!this.currentState) {
                this.updateState('DESC')
                return
            }

            if (this.currentState === 'DESC') {
                this.updateState('ASC')
                return
            }

            this.updateState(null)
        }

        updateState(value: null | 'ASC' | 'DESC') {
            this.$store.commit('local_storage/setSort', value ? {field: this.item.field, value} : null)
        }
    }
