
    import {Component, Vue} from 'vue-property-decorator'
    import FitSize from '@/utils/FitSize.vue'
    import Requester from '@/utils/Requester'
    import {store} from '@/store/store'

    interface IRegistrationData {
        username?: string
        email?: string
        password?: string
        repeatPassword?: string
    }

    @Component({
        components: {
            FitSize,
        },
    })
    export default class SignUpCard extends Vue {
        data: IRegistrationData = {}

        minPasswordLength = 6

        loading = false

        showAlert = false

        handleTimeout = -1

        errorMessages = {}

        get validateEmail() {
            return [
                //emailValidation(this.data.email) ? false : 'Invalid email',
            ]
        }

        get validatePassword() {
            return [
                //countValidation(this.minPasswordLength, this.data.password) ? false : 'Password must be at least 6 characters',
                //sameValidation(this.data.password, this.data.repeatPassword) ? false : 'Password must match',
            ]
        }

        get validateRepeatPassword() {
            return [
                //sameValidation(this.data.password, this.data.repeatPassword) ? false : 'Password must match',
            ]
        }

        async registration() {
            this.errorMessages = {}
            this.loading = true

            const registrationResponse = await Requester.registration(
                this.data.email!,
                this.data.password!,
                this.data.repeatPassword!,
            )
            this.loading = false

            const data = registrationResponse?.response?.data

            if (data?.error_messages) {
                this.errorMessages = data.error_messages
                return
            }

            if (registrationResponse.status === 200) {
                store.commit('local_storage/accessToken', registrationResponse.data.token)
                await store.dispatch('local_variables/loadPage', '/')
            }

            this.showAlert = true
            window.clearTimeout(this.handleTimeout)
            this.handleTimeout = window.setTimeout(() => this.showAlert = false, 5000)
        }
    }
