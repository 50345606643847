import { render, staticRenderFns } from "./KeywordsTableMobile.vue?vue&type=template&id=1f75b473&scoped=true&"
import script from "./KeywordsTableMobile.vue?vue&type=script&lang=ts&"
export * from "./KeywordsTableMobile.vue?vue&type=script&lang=ts&"
import style0 from "./KeywordsTableMobile.vue?vue&type=style&index=0&id=1f75b473&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f75b473",
  null
  
)

export default component.exports