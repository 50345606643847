
    import {Component, Vue} from 'vue-property-decorator'
    import FitSize from '@/utils/FitSize.vue'
    import {ToggleState} from '@/typings/typings'
    import {store} from '@/store/store'

    @Component({
        components: {FitSize},
    })
    export default class DashboardScreenSelect extends Vue {
        states = ToggleState

        get currentState() {
            return store.getters['local_storage/toggleState']
        }

        set currentState(state: ToggleState) {
            store.commit('local_storage/setToggleState', state)
        }

        getClass(state: ToggleState) {
            return this.currentState === state ? 'active' : 'not-active'
        }
    }
