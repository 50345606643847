

    import {Component, Mixins} from 'vue-property-decorator'
    import {IApplication} from '@/typings/IApplication'
    import Requester from '@/utils/Requester'
    import GraphView from '@/views/Details/GraphView.vue'
    import FitSize from '@/utils/FitSize.vue'
    import TableFeedItem from '@/components/Table/Feed/TableFeedItem.vue'
    import AdaptiveMixin from '@/components/Base/AdaptiveMixin'
    import {IApplicationAdditionalData} from '@/typings/IApplicationAdditionalData'
    import DetailsScreenshots from '@/views/Details/DetailsScreenshots.vue'
    import DetailsTopPurchaseInApp from '@/views/Details/DetailsTopPurchaseInApp.vue'
    import DetailsDescription from '@/views/Details/DetailsDescription.vue'
    import DetailsKeywordsMetrics from '@/views/Details/DetailsKeywordsMetrics.vue'
    import {TOperatingSystem} from '@/typings/typings'

    @Component({
        components: {
            DetailsDescription,
            DetailsTopPurchaseInApp,
            DetailsScreenshots,
            GraphView,
            TableFeedItem,
            FitSize,
            DetailsKeywordsMetrics,
        },
    })
    export default class Details extends Mixins<AdaptiveMixin>(AdaptiveMixin) {
        loaded = false

        additionalData: IApplicationAdditionalData | null = null
        application: IApplication | null = null
        error: string | null = null
        additionalDataLoaded = false

        get topInAppPurchases() {
            return this.additionalData?.top_in_app_purchases
        }

        get description() {
            return this.additionalData?.description
        }

        get screenshots() {
            return this.additionalData?.screenshots
        }

        get os() {
            return this.application?.os
        }

        get formattedDate() {
            if (!this.application) {
                return
            }

            const date = new Date(this.application.updated_date)

            const month = date.toLocaleString('en', {month: 'long'})
            const year = date.getFullYear()

            return `${month} ${year}`
        }

        get titleText() {
            if (!this.application) {
                return ''
            }

            return `${this.application.name} $${this.application.revenue} Revenue ${this.application.downloads} downloads in ${this.formattedDate}`
        }

        formattedOs(os: TOperatingSystem) {
            return os === 'android' ? 'Android Google Play' : 'Apple Appstore'
        }

        async mounted() {
            try {
                this.application = await Requester.get<IApplication>(`apps/${this.$route.params.id}`)

                document.title = `${this.application.name} - Revenues / Downloads - ${this.formattedOs(this.application.os)}`

            } catch (e) {
                console.log({...e})
                if (e.response.status === 401) {
                    this.error = 'You need to be logged in to see the information.'
                } else {
                    this.error = 'Error occurred'
                }

            }
            this.loaded = true
            if (this.error) {
                return
            }

            this.$nextTick(this.requestAdditionalData)
        }

        async requestAdditionalData() {
            if (this.additionalDataLoaded) {
                return
            }

            try {
                this.additionalData = await Requester.get<IApplicationAdditionalData>(`apps/additional-data/${this.$route.params.id}`)
            } catch(e) {
                // ignore
            }
            this.additionalDataLoaded = true
        }
    }
