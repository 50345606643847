
    import FitSize from '../../utils/FitSize.vue'
    import {Component, Mixins} from 'vue-property-decorator'
    import IconPathsMixin from '@/components/Base/IconPathsMixin'
    import AdaptiveMixin from '@/components/Base/AdaptiveMixin'
    import FooterDesktop from '@/components/Footer/FooterDesktop.vue'
    import {IFooterContent} from '@/components/Footer/typings'
    import FooterMobile from '@/components/Footer/FooterMobile.vue'

    @Component({
        components: {FooterDesktop, FooterMobile, FitSize},
    })
    export default class Footer extends Mixins<IconPathsMixin, AdaptiveMixin>(IconPathsMixin, AdaptiveMixin) {
        contents: IFooterContent = [
            {
                title: 'PRODUCT',
                items: [
                    {
                        title: 'About',
                        url: '#',
                    },
                    {
                        title: 'Features',
                        url: '#',
                    },
                    {
                        title: 'Pricing',
                        url: '#',
                    },
                ],
            },
            {
                title: 'COMPANY',
                items: [
                    {
                        title: 'Terms of service',
                        url: '/terms_of_service.pdf',
                    },
                    {
                        title: 'Privacy policy',
                        url: '/privacy_policy.pdf',
                    },
                ],
            },
            {
                title: 'FOLLOW US',
                items: [
                    {
                        title: 'Facebook',
                        url: 'https://www.facebook.com/dataplazma',
                    },
                    {
                        title: 'Twitter',
                        url: 'https://twitter.com/dataplazma',
                    },
                ],
            },
        ]

        get height() {
            return this.isMobile ? 568 : 268
        }
    }
