
    import {Component, Mixins, Prop} from 'vue-property-decorator'
    import AdaptiveMixin from '@/components/Base/AdaptiveMixin'

    @Component
    export default class DetailsScreenshots extends Mixins<AdaptiveMixin>(AdaptiveMixin) {
        @Prop({required: true}) value!: Array<string> | null
        @Prop({required: true}) loaded!: boolean

        currentIndex = 0

        get height() {
            return this.isMobile ? 440 : 640
        }
    }
