
    import {Component, Mixins, Prop} from 'vue-property-decorator'
    import IconPathsMixin from '@/components/Base/IconPathsMixin'
    import InlineSvg from 'vue-inline-svg'
    import FormatHelper from '@/utils/FormatHelper'

    enum StarType {
        Filled,
        Split,
        Empty,
    }

    @Component({components: {InlineSvg}})
    export default class RatingView extends Mixins<IconPathsMixin, FormatHelper>(IconPathsMixin, FormatHelper) {
        @Prop({required: true}) rating!: number

        types = StarType

        get ratingFormatted() {
            return Number(this.formatRating(this.rating))
        }

        getColor(type: StarType) {
            switch (type) {
                case StarType.Empty:
                    return '#D1D1D1'
                case StarType.Filled:
                    return '#FFCC29'
            }
        }

        getStarType(index: number) {
            if (this.rating - index >= 0) {
                return StarType.Filled
            }

            if (this.rating - index > -1) {
                return StarType.Split
            }

            return StarType.Empty
        }
    }
