
    import {Component, Mixins} from 'vue-property-decorator'
    import FilterItem from '@/components/Table/FilterItem.vue'
    import {filters, IFilterGroup, ISortingItem, sortings} from '@/components/Table/data'
    import AdaptiveMixin from '@/components/Base/AdaptiveMixin'
    import FilterItemMultiple from '@/components/Table/FilterItemMultiple.vue'
    import IconPathsMixin from '@/components/Base/IconPathsMixin'
    import SortingItem from '@/components/Table/SortingItem.vue'

    @Component({
        components: {SortingItem, FilterItemMultiple, FilterItem},
    })
    export default class FiltersCard extends Mixins<AdaptiveMixin, IconPathsMixin>(AdaptiveMixin, IconPathsMixin) {
        filterItems: Array<IFilterGroup> = filters
        sortItems: Array<ISortingItem> = sortings
    }
