
    import {Component, Mixins, Prop} from 'vue-property-decorator'
    import AdaptiveMixin from '@/components/Base/AdaptiveMixin'

    @Component
    export default class FitSize extends Mixins<AdaptiveMixin>(AdaptiveMixin) {
        @Prop({default: ''}) innerClass!: string
        @Prop({default: 60}) width!: number
        @Prop({default: 95}) mobileWidth!: number

        get currentWidth() {
            return this.isMobile ? this.mobileWidth : this.width
        }
    }
