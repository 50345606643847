
    import {Component, Mixins} from 'vue-property-decorator'
    import {IApplication} from '@/typings/IApplication'
    import {DataViewType} from '@/typings/typings'
    import {IRequestMeta} from '@/typings/IRequestMeta'
    import {store} from '@/store/store'
    import Requester from '@/utils/Requester'
    import TableContentViewMobile from '@/components/Table/Table/TableContentViewMobile.vue'
    import TableContentView from '@/components/Table/Table/TableContentView.vue'
    import TableFeedItemsGroup from '@/components/Table/Feed/TableFeedItemsGroup.vue'
    import TableControls from '@/components/Table/TableControls.vue'
    import SignUpContainer from '@/components/SignUp/SignUpContainer.vue'
    import AdaptiveMixin from '@/components/Base/AdaptiveMixin'
    import ItemLoaderMixin from '@/views/Dashboard/ItemLoaderMixin'

    @Component({
        components: {TableContentViewMobile, TableContentView, TableFeedItemsGroup, TableControls, SignUpContainer},
    })
    export default class AppsView extends Mixins<AdaptiveMixin, ItemLoaderMixin<IApplication>>(ItemLoaderMixin, AdaptiveMixin) {
        dataViewTypes = DataViewType

        keyword = ''

        get currentFilters() {
            return store.getters['local_storage/currentFilters']
        }

        get currentSort() {
            return store.getters['local_storage/currentSort']
        }

        get currentDataViewType() {
            return store.getters['local_storage/viewState']
        }

        async updateKeyword(value: string) {
            this.keyword = value
        }

        async requestItems() {
            return await Requester.get<{ data: Array<IApplication>, meta: IRequestMeta }>('apps/search', {
                page: this.currentPage,
                per_page: 20,
                keyword: this.keyword.length > 0 ? this.keyword : undefined,
                filters: this.currentFilters,
                sorting: this.currentSort ?? {},
            })
        }
    }
