
    import {Component, Vue} from 'vue-property-decorator'
    import SignUpContainer from '@/components/SignUp/SignUpContainer.vue'

    @Component({
        components: {
            SignUpContainer,
        }
    })
    export default class SignUp extends Vue {
    }
