
    import {Component, Mixins, Prop} from 'vue-property-decorator'
    import IApplicationAd from '@/typings/IApplicationAd'
    import VideoView from '@/components/VideoView.vue'
    import {IApplicationAdditionalData} from '@/typings/IApplicationAdditionalData'
    import Requester from '@/utils/Requester'
    import {IApplication} from '@/typings/IApplication'
    import {store} from '@/store/store'
    import ImageLoader from '@/utils/ImageLoader.vue'
    import AdaptiveMixin from '@/components/Base/AdaptiveMixin'

    @Component({
        components: {
            VideoView,
            ImageLoader,
        }
    })
    export default class AdView extends Mixins<AdaptiveMixin>(AdaptiveMixin) {
        @Prop({required: true}) application!: IApplication
        @Prop({required: true}) item!: IApplicationAd | null
        @Prop({required: true}) additionalData!: IApplicationAdditionalData | null
        @Prop({default: false}) multiple!: boolean

        requestedAdditionalData: IApplicationAdditionalData | null = null
        additionalDataLoad = false
        error = false

        get appIsNotAvailable() {
            return this.application && this.application.os === 'android'
                ? 'App Not Available in Google Play'
                : 'App Not Available in Appstore'
        }

        get isLogged() {
            return store.getters['local_storage/isLogged']
        }

        get screenshots() {
            return (this.applicationAdditionalData?.screenshots ?? []).slice(0, 3)
        }

        get applicationAdditionalData() {
            if (this.additionalData) {
                return this.additionalData
            }

            return this.requestedAdditionalData
        }

        get adObject() {
            const adObject = this.item?.ad_object
            if (!adObject) {
                return {ad_type: 'null'}
            }

            if (typeof adObject === 'string') {
                return JSON.parse(adObject)
            }

            return adObject
        }

        async loadAdditionalData() {
            this.additionalDataLoad = true
            try {
                this.requestedAdditionalData = await Requester.get<IApplicationAdditionalData>(`apps/additional-data/${this.application.id}`)
            } catch (e) {
                this.error = true
                // ignore
            }
            this.additionalDataLoad = false
        }

        async mounted() {
            if (this.adObject.ad_type !== 'null') {
                return
            }

            if (this.additionalData) {
                return
            }

            this.$nextTick(() => this.loadAdditionalData())
        }
    }
