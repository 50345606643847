
    import {Component, Mixins} from 'vue-property-decorator'
    import KeywordsTable from '@/components/KeywordsTable/KeywordsTable.vue'
    import KeywordsTableMobile from '@/components/KeywordsTable/KeywordsTableMobile.vue'
    import Requester from '@/utils/Requester'
    import {IApplication} from '@/typings/IApplication'
    import {IRequestMeta} from '@/typings/IRequestMeta'
    import AdaptiveMixin from '@/components/Base/AdaptiveMixin'
    import ItemLoaderMixin from '@/views/Dashboard/ItemLoaderMixin'

    @Component({
        components: {
            KeywordsTable,
            KeywordsTableMobile,
        },
    })
    export default class KeywordView extends Mixins<AdaptiveMixin, ItemLoaderMixin<IApplication>>(ItemLoaderMixin, AdaptiveMixin) {
        async requestItems() {
            return await Requester.get<{ data: Array<IApplication>, meta: IRequestMeta }>('keywords/search', {
                page: this.currentPage,
                per_page: 20,
            })
        }
    }
