

    import {Component, Mixins} from 'vue-property-decorator'
    import GraphView from '@/views/Details/GraphView.vue'
    import FitSize from '@/utils/FitSize.vue'
    import TableFeedItem from '@/components/Table/Feed/TableFeedItem.vue'
    import AdaptiveMixin from '@/components/Base/AdaptiveMixin'
    import {CHECK_STATUSES, ICheckPayment} from '@/views/Subscription/typings'
    import SubscriptionCircle from '@/views/Subscription/SubscriptionCircle.vue'
    import Requester from '@/utils/Requester'
    import SignUpContainer from '@/components/SignUp/SignUpContainer.vue'
    import {store} from '@/store/store'

    @Component({
        components: {
            GraphView,
            TableFeedItem,
            FitSize,
            SubscriptionCircle,
            SignUpContainer,
        },
    })
    export default class Subscription extends Mixins<AdaptiveMixin>(AdaptiveMixin) {
        requestInterval = -1

        state: CHECK_STATUSES = CHECK_STATUSES.LOADING

        url: string | null = null
        statuses = CHECK_STATUSES
        buyButtonLoading = false

        get price() {
            return `$499`
        }

        async makePayment() {
            this.buyButtonLoading = true
            const paymentUrl = await Requester.get('make-payment') as string
            this.buyButtonLoading = false

            window.open(paymentUrl)

            window.clearInterval(this.requestInterval)
            this.requestInterval = window.setInterval(async () => await this.checkHasPayment(), 5000)
            await this.checkHasPayment()
        }

        async checkHasPayment() {
            try {
                const data = await Requester.get<ICheckPayment>('check-payment/')
                this.state = data.status
                this.url = data.url
            } catch {
                this.state = CHECK_STATUSES.UNHANDLED
            }

            if (this.state !== CHECK_STATUSES.LAST_PAYMENT_STATUS_WAIT) {
                window.clearInterval(this.requestInterval)
            }
        }

        async redirectToMainPage() {
            await store.dispatch('local_variables/loadPage', '/')
        }

        async mounted() {
            await this.checkHasPayment()
            if (this.state === CHECK_STATUSES.LAST_PAYMENT_STATUS_WAIT) {
                this.requestInterval = window.setInterval(async () => await this.checkHasPayment(), 5000)
            }
        }
    }
