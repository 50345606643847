import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard2 from '@/views/Dashboard/Dashboard2.vue'
import Favorites2 from '@/views/Favorites/Favorites2.vue'
import SignUp from '@/views/SignUp.vue'
import Details from '@/views/Details/Details.vue'
import Subscription from '@/views/Subscription/Subscription.vue'
import ForgetPassword from '@/views/PasswordRecovery/ForgetPassword.vue'
import PageNotFound from '@/views/UtilsPage/PageNotFound.vue'
import PasswordRecovery from '@/views/PasswordRecovery/PasswordRecovery.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard2,
    },
    {
    	path: '/favorites',
    	name: 'Favorites',
    	component: Favorites2,
    	meta: {
    		auth: true,
    	}
    },
    {
        path: '/sign-up',
        name: 'Sign Up',
        component: SignUp,
    },
    {
    	path: '/details/:id',
    	name: 'Details',
    	component: Details,
    },
    {
        path: '/subscription',
        name: 'Subscription',
        component: Subscription,
        meta: {
            auth: true
        }
    },
    {
        path: '/forget-password',
        name: 'Forget Password',
        component: ForgetPassword,
    },
    {
        path: '/password-recovery/:key',
        name: 'Password Recovery',
        component: PasswordRecovery,
    },
    {
        path: '*',
        name: '404',
        component: PageNotFound,
    }
]

const router = new VueRouter({
    mode: 'history',
    routes,
})

/*
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.auth)) {
        if (!localStorage.getItem('access_token')) {
            next({
                name: 'Login',
                params: {nextUrl: to.fullPath},
            })
        } else {
            jsonwebtoken.verify(localStorage.getItem('access_token'), process.env.VUE_APP_JWT_SECRET, (err, decoded) => {
                if (err) {
                    localStorage.removeItem('access_token')
                    next({
                        name: 'Login',
                        params: {nextUrl: to.fullPath},
                    })
                } else {
                    next()
                }
            })
        }
    } else {
        next()
    }
})
*/

export default router
