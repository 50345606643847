
    import {Component, Vue, Prop} from 'vue-property-decorator'
    import {IApplication} from '@/typings/IApplication'

    @Component
    export default class ParseDate extends Vue {
        @Prop({required: true}) value!: IApplication

        get formattedDate() {
            const date = new Date(this.value.updated_date)

            const month = date.toLocaleString('en', { month: 'long' })
            const year = date.getFullYear()

            return `${month} ${year}`
        }
    }
