
    import {Component, Prop, Vue} from 'vue-property-decorator'
    import AdView from '@/components/Table/Feed/AdView.vue'
    import {IApplication} from '@/typings/IApplication'
    import AppTitle from '@/components/Table/Feed/AppTitle.vue'
    import ParseDate from '@/components/Table/Feed/ParseDate.vue'
    import AppInfo from '@/components/Table/Feed/AppInfo.vue'
    import AddToFavoritesButton from '@/components/Table/Feed/AddToFavoritesButton.vue'
    import {store} from '@/store/store'
    import {IApplicationAdditionalData} from '@/typings/IApplicationAdditionalData'

    @Component({
    components: {
        AppTitle,
        AdView,
        ParseDate,
        AppInfo,
        AddToFavoritesButton,
    }
    })
    export default class TableFeedItem extends Vue {
        @Prop({required: true}) item!: IApplication
        @Prop({default: () => null}) additionalData!: IApplicationAdditionalData
        @Prop({default: true}) needHover!: boolean
        @Prop({default: false}) rating!: boolean
        @Prop({default: false}) details!: boolean

        get lastAd() {
            if (!this.item.application_ads || !this.item.application_ads.length) {
                return null
            }

            return this.item.application_ads[this.item.application_ads.length - 1]
        }

        get isLogged() {
            return store.getters['local_storage/isLogged']
        }

        async redirectToDescription() {
            if (!this.needHover) {
                return
            }

            if (!this.isLogged) {
                this.$store.commit('local_variables/loginPopup', true)
                return
            }

            await store.dispatch('local_variables/loadPage', `/details/${this.item.id}`)
        }
    }
