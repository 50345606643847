
    import {Component, Mixins} from 'vue-property-decorator'
    import FitSize from '@/utils/FitSize.vue'
    import InlineSvg from 'vue-inline-svg'
    import IconPathsMixin from '@/components/Base/IconPathsMixin'
    import {ItemsContent} from '@/components/Base/ItemsContent'
    import SubscriptionErrorText from '@/components/Table/SubscriptionErrorText.vue'
    import {IMetricsByApplicationKeyword} from '@/typings/IMetricsByApplicationKeyword'
    import TopChartsTableContentItemMobile from '@/components/TopChartsTable/TopChartsTableContentItemMobile.vue'
    import {getCategoryName, ITopChartsItemsGroup} from '@/components/TopChartsTable/utils'
    import {ITopChart} from '@/typings/ITopChart'

    @Component({
        components: {
            TopChartsTableContentItemMobile,
            FitSize,
            InlineSvg,
            SubscriptionErrorText,
        },
    })
    export default class TopChartsTableMobile extends Mixins<IconPathsMixin, ItemsContent<ITopChart>>(IconPathsMixin, ItemsContent) {
        get groupedItems() {
            const result: Record<string, ITopChartsItemsGroup> = {}

            for (const item of this.items) {
                const key = `${item.type}_${item.category}`
                if (!result[key]) {
                    result[key] = {
                        type: item.type,
                        category: item.category,
                        items: [item]
                    }
                } else {
                    result[key].items.push(item)
                }
            }

            return Object.values(result)
        }

        formatCategoryName(value: string) {
            return getCategoryName(value)
        }

        formatType(type: 'free' | 'grossing') {
            switch (type) {
                case 'free':
                    return 'Installs'
                case 'grossing':
                    return 'Grossing'
            }
        }
    }
