import mainBg from '@/assets/images/bg/background.png'
import starIcon from '@/assets/images/icons/star.svg'
import splittedStarIcon from '@/assets/images/icons/splitted-star.svg'
import heartIcon from '@/assets/images/icons/heart-icon.png'
import searchIcon from '@/assets/images/icons/search.svg'
import selectFeedIcon from '@/assets/images/icons/select-feed-icon.svg'
import selectTableIcon from '@/assets/images/icons/select-table-icon.svg'
import filterIcon from '@/assets/images/icons/filter.svg'
import androidIcon from '@/assets/images/icons/android-icon.svg'
import iosIcon from '@/assets/images/icons/apple-icon.svg'
import closeIcon from '@/assets/images/icons/close-icon.svg'
import playVideoIcon from '@/assets/images/icons/play-button.svg'
import downloadsMobileIcon from '@/assets/images/icons/downloads-mobile-icon.svg'
import revenueMobileIcon from '@/assets/images/icons/revenue-mobile-icon.svg'

export default class IconsContainer {
    get downloadsMobileIcon() {
        return downloadsMobileIcon
    }
    get revenueMobileIcon() {
        return revenueMobileIcon
    }

    get playVideoIcon() {
        return playVideoIcon
    }

    get mainBg() {
        return mainBg
    }

    get heartIcon() {
        return heartIcon
    }

    get starIcon() {
        return starIcon
    }

    get splittedStarIcon() {
        return splittedStarIcon
    }

    get searchIcon() {
        return searchIcon
    }

    get selectFeedIcon() {
        return selectFeedIcon
    }

    get selectTableIcon() {
        return selectTableIcon
    }

    get filterIcon() {
        return filterIcon
    }

    get androidIcon() {
        return androidIcon
    }

    get iosIcon() {
        return iosIcon
    }

    get closeIcon() {
        return closeIcon
    }
}