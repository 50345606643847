
    import {Component, Mixins, Prop} from 'vue-property-decorator'
    import InlineSvg from 'vue-inline-svg';
    import IconPathsMixin from '@/components/Base/IconPathsMixin'
    import {DataViewType} from '@/typings/typings'
    import AdaptiveMixin from '@/components/Base/AdaptiveMixin'
    import {store} from '@/store/store'

    interface IGroupButton {
        icon: string
        type: DataViewType,
    }

    @Component({
        components: {
            InlineSvg,
        }
    })
    export default class TableToggleButtons extends Mixins<IconPathsMixin, AdaptiveMixin>(IconPathsMixin, AdaptiveMixin) {
        @Prop({default: true}) hasFilters!: boolean
        hoveredIndex: DataViewType | -1 = -1

        buttons: Array<IGroupButton> = [
            {
                icon: this.icons.selectTableIcon,
                type: DataViewType.TABLE,
            },
            {
                icon: this.icons.selectFeedIcon,
                type: DataViewType.FEED,
            },
        ]

        get currentDataViewType() {
            return store.getters['local_storage/viewState']
        }

        get showFilters() {
            return store.getters['local_storage/filtersOpened']
        }

        get filtersClass() {
            return this.showFilters ? 'active' : 'unactive'
        }

        get filterButtonColor() {
            return this.showFilters ? '#EBEFFF' : '#ffffff'
        }

        get filterIcon() {
            return '#252525'
        }

        updateShowFilters() {
            store.commit('local_storage/filtersOpened', !this.showFilters)
        }

        updateDataView(value: DataViewType) {
            store.commit('local_storage/viewState', value)
        }

        color(value: DataViewType) {
            if (this.currentDataViewType === value) {
                return '#5075F6'
            }

            return this.hoveredIndex === value ? '#8BA3FF' : '#D1D1D1'
        }
    }
