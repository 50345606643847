
    import {Component, Vue} from 'vue-property-decorator'
    import FitSize from '@/utils/FitSize.vue'
    import {countValidation, emailValidation, sameValidation} from '@/utils/validation'
    import Reference from "@/../helpers/reference/reference.json";

    interface IBillingDetailsData {
        firstName?: string
        lastName?: string
        country?: string
        state?: string
        city?: string
        address?: string
        postcode?: string
    }

    @Component({
        components: {
            FitSize,
        },
    })
    export default class BillingDetailsCard extends Vue {
        data: IBillingDetailsData = {}

        minPasswordLength = 6

        get countries() {
            return Reference.countries
        }

        get validateEmail() {
            return [
                emailValidation(this.data.email) ? false : 'Invalid email',
            ]
        }

        get validatePassword() {
            return [
                countValidation(this.minPasswordLength, this.data.password) ? false : 'Password must be at least 6 characters',
                sameValidation(this.data.password, this.data.repeatPassword) ? false : 'Password must match',
            ]
        }

        get validateRepeatPassword() {
            return [
                sameValidation(this.data.password, this.data.repeatPassword) ? false : 'Password must match',
            ]
        }
    }
