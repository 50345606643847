
    import {Component, Mixins} from 'vue-property-decorator'
    import IconPathsMixin from '@/components/Base/IconPathsMixin'
    import AdaptiveMixin from '@/components/Base/AdaptiveMixin'

    @Component
    export default class TableSearch extends Mixins<IconPathsMixin, AdaptiveMixin>(AdaptiveMixin, IconPathsMixin) {
        keyword = ''

        updateKeyword(keyword: string) {
            this.keyword = keyword
            this.$emit('update:keyword', keyword)
        }
    }
