
import {Component, Mixins, Prop} from 'vue-property-decorator'
import {IApplication} from '@/typings/IApplication'
import IconPathsMixin from '@/components/Base/IconPathsMixin'
import AppTitle from '@/components/Table/Feed/AppTitle.vue'
import TableFavoriteIcon from '@/components/Table/Table/TableFavoriteIcon.vue'
import FormatHelper from '@/utils/FormatHelper'
import AddToFavoritesButton from "@/components/Table/Feed/AddToFavoritesButton.vue";
import {store} from "@/store/store";

@Component({
    components: {
        AppTitle,
        TableFavoriteIcon,
        AddToFavoritesButton
    },
})
export default class MobileTableContentItem extends Mixins<IconPathsMixin, FormatHelper>(IconPathsMixin, FormatHelper) {
    @Prop({required: true}) item!: IApplication
    isExpand = false

    get isLogged() {
        return store.getters['local_storage/isLogged']
    }

    get color() {
        return this.isExpand ? 'primary' : ''
    }

    get icon() {
        return this.isExpand ? 'mdi-menu-up' : 'mdi-menu-down'
    }

    redirectToUrl() {
        window.open(this.item.url)
    }

    async redirectToDescription() {
        if (!this.isLogged) {
            this.$store.commit('local_variables/loginPopup', true)
            return
        }

        await store.dispatch('local_variables/loadPage', `/details/${this.item.id}`)
    }
}
