
    import {Component, Mixins} from 'vue-property-decorator'
    import HeaderContent from '@/components/Header/HeaderContent.vue'
    import FavoritesButton from '@/components/Header/FavoritesButton.vue'
    import {HEADER_HEIGHT, MOBILE_HEADER_HEIGHT} from '@/utils/constants'
    import FitSize from '@/utils/FitSize.vue'
    import SignInButton from '@/components/Header/SignInButton.vue'
    import AdaptiveMixin from '@/components/Base/AdaptiveMixin'
    import {store} from '@/store/store'

    @Component({
        components: {
            HeaderContent,
            FavoritesButton,
            FitSize,
            SignInButton,
        },
    })
    export default class Header extends Mixins<AdaptiveMixin>(AdaptiveMixin) {

        get favoritesAvailable() {
            return this.isFavorites
        }

        get isFavorites() {
            return this.$route.path === '/favorites'
        }

        get isLogged() {
            return store.getters['local_storage/isLogged']
        }

        get accountName() {
            return store.getters['local_storage/loggedUser']?.login ?? null
        }

        get headerHeight() {
            return this.isMobile ? MOBILE_HEADER_HEIGHT : HEADER_HEIGHT
        }

        async openSubscription() {
            await store.dispatch('local_variables/loadPage', '/subscription')
        }

        async redirectToMainPage() {
            await store.dispatch('local_variables/loadPage', '/')
        }

        async redirectToSignUp() {
            await store.dispatch('local_variables/loadPage', '/sign-up')
        }
    }
