
    import {Component, Mixins, Prop} from 'vue-property-decorator'
    import IconPathsMixin from '@/components/Base/IconPathsMixin'

    @Component
    export default class VideoView extends Mixins<IconPathsMixin>(IconPathsMixin) {
        @Prop({required: true}) videoUrl!: string
        @Prop({required: true}) previewUrl!: string

        videoLoaded = false
    }
