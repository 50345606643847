
    import Header from '@/components/Header/Header.vue'
    import {Component, Mixins, Provide} from 'vue-property-decorator'
    import IconsContainer from '@/utils/IconsContainer'
    import {INJECT} from '@/utils/constants'
    import MainBackground from '@/components/MainBackground.vue'
    import Footer from '@/components/Footer/Footer.vue'
    import AdaptiveMixin from '@/components/Base/AdaptiveMixin'
    import {store} from '@/store/store'
    import SingInDialog from '@/components/Modals/SingInDialog.vue'

    @Component({
        components: {
            Header,
            MainBackground,
            Footer,
            SingInDialog,
        },
    })
    export default class App extends Mixins<AdaptiveMixin>(AdaptiveMixin) {
        @Provide(INJECT.ICON_CONTAINER) iconsContainer: IconsContainer = new IconsContainer

        get signInDialog() {
            return this.$store.getters['local_variables/loginPopup']
        }

        set signInDialog(value: boolean) {
            this.$store.commit('local_variables/loginPopup', value)
        }

        async mounted() {
            await store.dispatch('favorites/loadFavoritesIds')
        }
    }
