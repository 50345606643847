
    import {Component, Mixins} from 'vue-property-decorator'
    import Requester from '@/utils/Requester'
    import {IApplication} from '@/typings/IApplication'
    import {IRequestMeta} from '@/typings/IRequestMeta'
    import AdaptiveMixin from '@/components/Base/AdaptiveMixin'
    import ItemLoaderMixin from '@/views/Dashboard/ItemLoaderMixin'
    import TopChartsTable from '@/components/TopChartsTable/TopChartsTable.vue'
    import TopChartControls from '@/components/TopChartsTable/TopChartControls.vue'
    import TopChartsTableMobile from '@/components/TopChartsTable/TopChartsTableMobile.vue'

    @Component({
        components: {
            TopChartsTable,
            TopChartsTableMobile,
            TopChartControls,
        },
    })
    export default class TopChartsView extends Mixins<AdaptiveMixin, ItemLoaderMixin<IApplication>>(ItemLoaderMixin, AdaptiveMixin) {
        currentDate: Date | null = null

        async onDateUpdated(date: Date) {
            this.currentDate = date
            await this.reload()
        }

        async requestItems() {
            if (!this.currentDate) {
                return []
            }

            return await Requester.get<{ data: Array<IApplication>, meta: IRequestMeta }>('/top-charts/search/', {
                date: this.currentDate,
                page: this.currentPage,
                per_page: 20,
            })
        }
    }
