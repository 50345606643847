
    import {Component, Mixins} from 'vue-property-decorator'
    import IconPathsMixin from '@/components/Base/IconPathsMixin'
    import {store} from '@/store/store'
    import Requester from '@/utils/Requester'

    @Component
    export default class SingInDialog extends Mixins<IconPathsMixin>(IconPathsMixin) {

        login: string = ''
        password: string = ''
        errors: Array<string> = []

        loading = false

        handleChange() {
            this.errors = []
        }

        async redirectToForgetPassword() {
            await store.dispatch('local_variables/loadPage', '/forget-password')
        }

        async sendLogin() {
            this.loading = true
            const token = await Requester.auth(this.login, this.password)
            this.loading = false

            if (!token) {
                this.errors = ['Wrong login or password']
                return
            }

            store.commit('local_storage/accessToken', token)
            this.$emit('close')
        }
    }
