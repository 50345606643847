import {store} from '@/store/store'

require('datejs');

import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import vuetify from '@/plugins/vuetify'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.config.productionTip = false
const vue = new Vue({
  router,
  store,
  render: h => h(App),
  vuetify,
}).$mount('#app')
