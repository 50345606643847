
    import {Component, Mixins} from 'vue-property-decorator'
    import FitSize from '@/utils/FitSize.vue'
    import InlineSvg from 'vue-inline-svg'
    import IconPathsMixin from '@/components/Base/IconPathsMixin'
    import {ItemsContent} from '@/components/Base/ItemsContent'
    import TableContentViewMobile from '@/components/Table/Table/TableContentItemMobile.vue'
    import SubscriptionErrorText from '@/components/Table/SubscriptionErrorText.vue'
    import {IApplication} from '@/typings/IApplication'
    // TODO inj735 slots for each item?

    @Component({
        components: {
            FitSize,
            InlineSvg,
            TableContentViewMobile,
            SubscriptionErrorText,
        },
    })
    export default class TableContentView extends Mixins<IconPathsMixin, ItemsContent<IApplication>>(IconPathsMixin, ItemsContent) {
    }
