
    import {Component, Mixins} from 'vue-property-decorator'
    import FitSize from '@/utils/FitSize.vue'
    import InlineSvg from 'vue-inline-svg'
    import IconPathsMixin from '@/components/Base/IconPathsMixin'
    import {ItemsContent} from '@/components/Base/ItemsContent'
    import TableFavoriteIcon from '@/components/Table/Table/TableFavoriteIcon.vue'
    import FormatHelper from '@/utils/FormatHelper'
    import {IApplication} from "@/typings/IApplication";
    import SubscriptionErrorText from '@/components/Table/SubscriptionErrorText.vue'
    import {store} from '@/store/store'

    // TODO inj735 slots for each item?

    @Component({
        components: {
            FitSize,
            InlineSvg,
            TableFavoriteIcon,
            SubscriptionErrorText
        },
    })
    export default class TableContentView extends Mixins<IconPathsMixin, ItemsContent<IApplication>, FormatHelper>(FormatHelper, IconPathsMixin, ItemsContent) {

        headers = [
            {
                text: 'OS',
                value: 'os',
            },
            {
                text: 'Name',
                value: 'name',
            },
            {
                text: 'Downloads',
                value: 'downloads',
            },
            {
                text: 'Revenue',
                value: 'revenue',
            },
            {
                text: 'Ad',
                value: 'ad',
            },
            {
                text: 'Categories',
                value: 'categories',
            },
            {
                text: 'First Release',
                value: 'release_date',
            },
            {
                text: 'Last Update',
                value: 'updated_date',
            },
            {
                text: 'All Ratings',
                value: 'ratings',
            },
            {
                text: 'Rating',
                value: 'rating',
            },
            {
                text: 'Traffic Cost',
                value: 'traffic_cost',
            },
            {
                text: 'ROAS',
                value: 'roas',
            },
            {
                text: '',
                value: 'favorites',
            },
        ]

        get isLogged() {
            return store.getters['local_storage/isLogged']
        }

        redirectToUrl(value: IApplication) {
            window.open(value.url)
        }

        getFillColor() {
            return '#D1D1D1'
        }

        async onRowClick(item: IApplication) {
            if (!this.isLogged) {
                this.$store.commit('local_variables/loginPopup', true)
                return
            }

            await store.dispatch('local_variables/loadPage', `/details/${item.id}`)
        }
    }
