import {DataViewType, ToggleState} from '@/typings/typings'
import {IUserInfo} from '@/typings/IUserInfo'
import Vue from 'vue'
import {ISortingValue} from '@/components/Table/data'

export interface ILocalStorageModule {
    toggleState: ToggleState
    accessToken: null | string
    filtersOpened: null | boolean
    viewState: null | DataViewType
    currentFilters: Record<string, string | number>
    currentSort: ISortingValue | null
}

export default {
    namespaced: true,
    state: {
        toggleState: getStorageState().toggleState ?? ToggleState.Apps,
        accessToken: getStorageState().accessToken ?? null,
        filtersOpened: getStorageState().filtersOpened ?? false,
        viewState: getStorageState().viewState ?? DataViewType.FEED,
        currentFilters: getStorageState().currentFilters ?? getFiltersDefaultValue(),
        currentSort: getStorageState().currentSort ?? null,
    } as ILocalStorageModule,
    getters: {
        toggleState: state => state.toggleState ?? getStorageState().toggleState ?? ToggleState.Apps,
        accessToken: state => state.accessToken ?? getStorageState().accessToken ?? null,
        isLogged: state => {
            if (!state.accessToken) {
                return false
            }

            const exp = parseToken(state.accessToken)?.exp ?? 0
            return exp * 1000 - new Date().getTime() > 0
        },
        loggedUser: (state, getters) => {
            if (!getters.isLogged) {
                return null
            }

            return parseToken(state.accessToken)! as IUserInfo
        },
        filtersOpened: state => state.filtersOpened ?? getStorageState().filtersOpened ?? false,
        viewState: state => state.viewState ?? getStorageState().viewState ?? DataViewType.FEED,
        currentFilters: state => state.currentFilters ?? getStorageState().currentFilters ?? getFiltersDefaultValue() ?? {},
        currentSort: state => state.currentSort ?? getStorageState().currentSort ?? null,
    },
    mutations: {
        setToggleState(state, value: ToggleState) {
            state.toggleState = value
            saveStorage(state)
        },
        filtersOpened(state, value: boolean) {
            state.filtersOpened = value
            saveStorage(state)
        },
        viewState(state, value: DataViewType) {
            state.viewState = value
            saveStorage(state)
        },
        accessToken(state, value: string) {
            state.accessToken = value
            saveStorage(state)
        },
        setSort(state, value: ISortingValue | null) {
            state.currentSort = value
            saveStorage(state)
        },
        addFilter(state, value: {type: string, value: string | number | Array<string | number> | null}) {
            if (value.value === null) {
                const filters = state.currentFilters
                delete filters[value.type]
                state.currentFilters = {
                    ...filters
                }
            } else {
                state.currentFilters = {
                    ...state.currentFilters,
                    [value.type]: value.value
                }
            }

            Vue.set(state, value.type, value.value)

            saveStorage(state)
        },
    },
    actions: {

    }
}

function getFiltersDefaultValue() {
    return {
        ad: 1
    }
}

function parseToken(token: string): IUserInfo | null {
    try {
        return JSON.parse(window.atob(token.split('.')[1])) as IUserInfo
    } catch {}

    return null
}

function getStorageState() {
    return JSON.parse(localStorage.getItem('state') ?? '{}') as ILocalStorageModule
}

function saveStorage(state: ILocalStorageModule) {
    localStorage.setItem('state', JSON.stringify(state))
}