import {Component, Prop, Vue
} from 'vue-property-decorator'
import {IApplication} from '@/typings/IApplication'
import {store} from '@/store/store'

@Component
export class FavoriteButtonMixin extends Vue {
    @Prop({required: true}) value!: IApplication

    get isFavorite() {
        return store.getters['favorites/favoritesIds'].some(id => this.value.id === id)
    }

    get color() {
        return this.isFavorite ? '#FFCC29' : '#D1D1D1'
    }

    async updateFavorite() {
        if (this.isFavorite) {
            await store.dispatch('favorites/removeFromFavorites', this.value.id)
            return
        }

        await store.dispatch('favorites/addToFavorites', this.value.id)
    }
}