import Vuex, {Store} from 'vuex'
import local_storage from '@/store/modules/local_storage'
import local_variables from '@/store/modules/local_variables'
import favorites from '@/store/modules/favorites'
import Vue from 'vue'

Vue.use(Vuex)

export const store = new Store<{}>({
    modules: {
        local_storage,
        local_variables,
        favorites,
    },
})