
    import {Component, Mixins, Prop} from 'vue-property-decorator'
    import TableSearch from '@/components/Table/TableSearch.vue'
    import FitSize from '@/utils/FitSize.vue'
    import TableToggleButtons from '@/components/Table/TableToggleButtons.vue'
    import TableFiltersCard from '@/components/Table/TableFiltersCard.vue'
    import AdaptiveMixin from '@/components/Base/AdaptiveMixin'
    import TableRecordsCount from "@/components/Table/TableRecordsCount.vue";
    import TopChartsTimeFilter from '@/components/TopChartsTable/TopChartsTimeFilter.vue'

    @Component({
        components: {TopChartsTimeFilter, TableFiltersCard, TableRecordsCount, TableSearch, TableToggleButtons, FitSize},
    })
    export default class TopChartControls extends Mixins<AdaptiveMixin>(AdaptiveMixin) {
        @Prop({default: () => null}) recordsCount!: number | null
    }
