
    import {Component, Mixins} from 'vue-property-decorator'
    import FitSize from '@/utils/FitSize.vue'
    import InlineSvg from 'vue-inline-svg'
    import IconPathsMixin from '@/components/Base/IconPathsMixin'
    import {ItemsContent} from '@/components/Base/ItemsContent'
    import SubscriptionErrorText from '@/components/Table/SubscriptionErrorText.vue'
    import {IMetricsByApplicationKeyword} from '@/typings/IMetricsByApplicationKeyword'
    import KeywordsTableContentItemMobile from '@/components/KeywordsTable/KeywordsTableContentItemMobile.vue'

    @Component({
        components: {
            KeywordsTableContentItemMobile,
            FitSize,
            InlineSvg,
            SubscriptionErrorText,
        },
    })
    export default class KeywordsTableMobile extends Mixins<IconPathsMixin, ItemsContent<IMetricsByApplicationKeyword>>(IconPathsMixin, ItemsContent) {
    }
