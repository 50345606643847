
    import {Component, Prop, Vue} from 'vue-property-decorator'
    import {IFilterItem} from '@/components/Table/data'
    import {store} from '@/store/store'

    @Component
    export default class FilterItem extends Vue {
        @Prop({required: true}) title!: string
        @Prop({required: true}) type!: string
        @Prop({default: () => []}) items!: Array<IFilterItem>

        get value() {
            return store.getters['local_storage/currentFilters'][this.type] ?? this.items[0].value
        }

        get isActive() {
            return this.value !== 'All' && this.value !== null && this.value !== undefined
        }

        get activeClass() {
            return this.isActive ? 'active-border' : 'default-border'
        }

        updateValue(value: any) {
            store.commit('local_storage/addFilter', {type: this.type, value})
        }
    }
