
    import {Component, Prop, Ref, Vue} from 'vue-property-decorator'
    import {IParsing} from '@/typings/IParsing'
    import ApexCharts from 'vue-apexcharts'
    import {ApexOptions} from 'apexcharts'

    @Component({
        components: {
            ApexCharts,
        },
    })
    export default class GraphView extends Vue {
        @Prop({}) chartData!: Array<IParsing>
        @Ref('r-parent') parentRef!: HTMLDivElement

        metrics = [
            {name: 'downloads', color: '#14278c'},
            {name: 'revenue', color: '#dc106c'},
            {name: 'ratings', color: '#e1d714'},
        ]

        width = 500
        height = 500

        get options(): ApexOptions {
            return {
                chart: {
                    id: 'vuechart-example',
                },
                tooltip: {
                    x: {
                        formatter: (v) => this.formatDate(this.chartData?.[v]?.parsed_at),
                    },
                },
                xaxis: {
                    categories: this.chartData.map(item => item.parsed_at),
                    labels: {
                        show: false,
                    },
                    tooltip: {
                        enabled: false,
                    },
                },
                stroke: {
                    curve: 'smooth',
                    width: 4,
                },
                chart: {
                    toolbar: {
                        show: false,
                    },
                },
            }
        }

        get series() {
            return this.metrics.map(v => ({
                name: v.name,
                data: this.getSeriesByName(v.name),
            }))
        }

        formatDate(date: string) {
            return new Date(date).toLocaleDateString('en-us', {month: 'short', year: 'numeric'})
        }

        getSeriesByName(field: string) {
            return this.chartData.map(data => data[field]).filter(v => v)
        }

        mounted() {
            this.width = this.parentRef.getBoundingClientRect().width
            this.height = this.parentRef.getBoundingClientRect().height - 150
        }
    }
