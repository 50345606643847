
    import {Component, Mixins} from 'vue-property-decorator'
    import IconPathsMixin from '@/components/Base/IconPathsMixin'
    import {formatCount} from '@/utils/utils'
    import AdaptiveMixin from "@/components/Base/AdaptiveMixin";
    import {store} from '@/store/store'

    @Component
    export default class FavoritesButton extends Mixins<IconPathsMixin, AdaptiveMixin>(IconPathsMixin, AdaptiveMixin) {

        get favoritesText() {
            return !this.isMobile ? 'Favorites' : ''
        }

        get isLogged() {
            return this.$store.getters['local_storage/isLogged']
        }

        get count() {
            return this.$store.getters['favorites/favoritesIds'].length
        }

        get countFormatted() {
            return formatCount(this.count)
        }

        async redirectToFavorites() {
            if (this.isLogged) {
                await store.dispatch('local_variables/loadPage', '/favorites')
            } else {
                this.$store.commit('local_variables/loginPopup', true)
            }
        }
    }
