
    import {Component, Prop, Vue} from 'vue-property-decorator'
    import {IApplication} from '@/typings/IApplication'
    import Requester from '@/utils/Requester'
    import DetailsTopAppsByKeyword from '@/views/Details/DetailsTopAppsByKeyword.vue'
    import {IMetricsResponse} from '@/typings/IMetricsResponse'
    import {IMetricsByApplicationKeyword} from '@/typings/IMetricsByApplicationKeyword'
    import {IMetricsByKeyword} from '@/typings/IMetricsByKeyword'
    import {
        IApplicationCachedRawData,
    } from '@/typings/IApplicationAdditionalData'

    @Component({
        components: {
            DetailsTopAppsByKeyword
        }
    })
    export default class DetailsKeywordsMetrics extends Vue {
        @Prop({required: true}) application!: IApplication

        metricsResponse: IMetricsResponse | null = null
        metricsList: Array<IMetricsByApplicationKeyword & IMetricsByKeyword> = []

        appsByKeyword: Record<string, Array<IApplicationCachedRawData>> = {}
        currentAppsByKeyword: Array<IApplicationCachedRawData> = []

        loaded = false
        showDialog = false
        currentKeyword: string | null = null

        notAuthorizedErrors = false

        async showTopApps(keyword: string) {
            this.currentAppsByKeyword = []
            this.showDialog = true
            this.currentKeyword = keyword

            if (!this.appsByKeyword[this.currentKeyword]) {
                this.appsByKeyword[this.currentKeyword] =
                    (await Requester.get<IMetricsResponse>(encodeURI(`top-apps/${this.application.os}/${this.currentKeyword}`)))
            }

            this.currentAppsByKeyword = this.appsByKeyword[this.currentKeyword]
        }

        getValueOrPlaceholder(value) {
            return value ?? '-'
        }

        get formattedResponse() {
            if (!this.metricsResponse) {
                return []
            }

            const {metrics_by_application, metrics_by_keyword} = this.metricsResponse
            const result: Array<IMetricsByApplicationKeyword & IMetricsByKeyword> = []

            for (const metric of metrics_by_application) {
                result.push({...metric, ...(metrics_by_keyword.find(v => v.keyword_id === metric.keyword_id) ?? {})})
            }

            return result
        }

        async mounted() {
            this.loaded = false
            try {
                this.metricsResponse = await Requester.get<IMetricsResponse>(`keyword-metrics/${this.application.id}`)
            } catch(e) {
                this.notAuthorizedErrors = e.response.status === 401
            }
            this.loaded = true
        }
    }
