
    import {Component, Vue, Watch} from 'vue-property-decorator'
    import FitSize from '@/utils/FitSize.vue'
    import Requester from '@/utils/Requester'
    import {store} from '@/store/store'

    interface IRecoverPasswordData {
        password?: string
        repeatPassword?: string
    }

    @Component({
        components: {
            FitSize,
        },
    })
    export default class PasswordRecovery extends Vue {
        data: IRecoverPasswordData = {}

        loading = false

        alert = false
        handleTimeout = -1
        errorMessages = {}

        alertType: 'error' | 'info' = 'error'

        alertMessage: string | null = null

        @Watch('data.email')
        onEmailUpdate() {
            this.errorMessages = {}
        }

        async recoverPassword() {
            this.errorMessages = {}
            this.loading = true

            const response = await Requester.recoverPassword(
                this.data.password!,
                this.data.repeatPassword!,
                this.$route.params.key,
            )
            this.loading = false

            const data = response?.response?.data

            if (data?.error_messages) {
                this.errorMessages = data.error_messages
                return
            }

            if (response?.status === 200) {
                this.showInfoAlert('Password restored!')

                store.commit('local_storage/accessToken', response.data.token)
                window.setTimeout(() => {
                    store.dispatch('local_variables/loadPage', '/')
                }, 2000)
                return
            }

            this.showErrorAlert(data ?? 'Error occurred')
        }

        showInfoAlert(message: string) {
            this.alertMessage = message
            this.alertType = 'info'
            this.showAlert()
        }

        showErrorAlert(message: string) {
            this.errorMessages.password = message
            this.alertMessage = message
            this.alertType = 'error'
            this.showAlert()
        }

        showAlert() {
            this.alert = true
            window.clearTimeout(this.handleTimeout)
            this.handleTimeout = window.setTimeout(() => this.alert = false, 5000)
        }
    }
