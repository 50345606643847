import {Component, Vue} from 'vue-property-decorator'

@Component
export default class AdaptiveMixin extends Vue {
    get adaptiveClass() {
        return this.isMobile ? 'mobile' : 'desktop'
    }

    get isMobile() {
        return this.$vuetify.breakpoint.xs
    }

    get isDesktop() {
        return !this.isMobile
    }
}