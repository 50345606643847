
    import {Component, Vue, Prop} from 'vue-property-decorator'

    @Component
    export default class SubscriptionCircle extends Vue {
        @Prop({required: true}) icon!: string
        @Prop({default: '#ffffff'}) iconColor!: string
        @Prop({required: true}) hexColor!: string
        @Prop({default: 60}) size!: number

        get parentColor() {
            return this.getColorWithOpacity(0.1)
        }

        get mediumColor() {
            return this.getColorWithOpacity(0.2)
        }

        get mainColor() {
            return this.getColorWithOpacity(0.5)
        }

        getColorWithOpacity(opacity: number) {
            return `rgba(${this.parsedColor[0]}, ${this.parsedColor[1]}, ${this.parsedColor[2]}, ${opacity})`
        }

        get parsedColor(): [number, number, number] {
            const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(this.hexColor);
            return [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)]
        }
    }
